import React, { useEffect } from 'react';
import Image_gif from './Image_gif';
import '../assets/css/preloader.css';

const Loader = () => {
    useEffect(() => {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
  
      // Enable scrolling when component unmounts
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, []);

    return (
    <div className="preloaderBg" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',backgroundColor:'rgb(0 0 0 / 50%)', width:'100%', height:'100%',zIndex:9999 }}>
    <div className="zoom-in-zoom-out">
<Image_gif text={'loader'}/>
  </div>
  </div>
 );
};

export default Loader;