import React, { createContext, useState, useEffect } from 'react';
import { getUserData } from '../components/Api';


export const AuthContext = createContext({
  isAuthenticated: false, 
  user: null,
  login: () => {}, 
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getUserData(token)
        .then(data => {
          if (data) {
            setIsAuthenticated(true);
            setUser(data);
          }
        })
        .catch(error => {
          console.error('Failed to fetch user data:', error);
          logout(); // Log out if there's an error fetching user data
        });
    }
  }, []);

  const login = (token, userData) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
  };
  

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};