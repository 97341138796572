import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as colors from "../assets/css/Colors";
import { Link } from "react-router-dom";
// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";
// api
import { getUserCount } from '../components/Api';
import Location from '../modal/Location';

const Section = () => {

  const [userCount, setUserCount] = useState(null);
  const [pincode, setPincode] = useState('');
  const [error, setError] = useState(null);
  const [initialUserCount, setInitialUserCount] = useState(null);

  const fetchUserCount = async (zipcode) => {
    try {
      const data = await getUserCount(zipcode);
      return data.data;
    } catch (error) {
      throw new Error('Failed to fetch user count');
    }
  };

  const handleLocationUpdate = async (updatedLocation) => {
    try {
      const pincodeMatch = updatedLocation.match(/\b\d{6}\b/);
      const newPincode = pincodeMatch ? pincodeMatch[0] : '';
      setPincode(newPincode);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmit = async () => {
    if (pincode.length === 6) {
      try {
        const data = await fetchUserCount(pincode);
        setUserCount(data);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    const fetchInitialUserCount = async () => {
      try {
        const data = await fetchUserCount(null); // Initial fetch with no pincode
        setInitialUserCount(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchInitialUserCount();
  }, []);

  useEffect(() => {
    if (pincode.length === 6) {
      handleSubmit();
    }
  }, [pincode]);









  // Start color change auto function
  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);
  // End color change auto function
  //  gradient color Start
  const styles = {
    background: colorScheme === 'dark'
      ? `linear-gradient(to right, ${colors.theme_black_color}, ${colors.theme_black_color}, ${colors.theme_white_color})`
      : `linear-gradient(to right, ${colors.theme_white_color}, ${colors.theme_white_color}, ${colors.theme_black_color})`,
  };
  const boxstyle = {
    backgroundColor: colorScheme === 'dark' ? colors.theme_black_color_bg : colors.theme_white_color_bg,
    border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
  }
  const boxstyleqrcode = {
    border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
    borderRadius: '8px',
  };
  // gradient color End


  // language Translate function start
  const { language } = useContext(LanguageContext);

  // language Translate function end


  return (
    <section className='section'>


      {/* <div>
        <h1>User Count</h1>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            placeholder="Enter Pincode"
          />
        </form>
        <Location onLocationUpdate={handleLocationUpdate} />
        {error && <div>Error: {error}</div>}
        {userCount && (
          <div>
            <h2>User Count for Pincode: {pincode}</h2>
            <p>Male Count: {userCount.maleCount}</p>
            <p>Female Count: {userCount.femaleCount}</p>
            <p>Total Count: {userCount.zipCount}</p>
          </div>
        )}
      </div> */}

<div className='loctionbuttonhide'>
<Location onLocationUpdate={handleLocationUpdate}/>
</div>



      <Container>
        <Row>
          <Col sm={12}>
            <div className="ad-dash-s1">
              <div className="cd-cen-intr-inn" style={styles}>
                <h2><b>{translate('welcome_card', language)}</b></h2>
                <p>{translate('welcome_card_p', language)}</p>
              </div>
            </div>
          </Col>
          <Col sm={12}>
            <div className="home-tit">
              <h2><span> {translate('localareasectio_title_span', language)} </span>&nbsp;{translate('localareasectio_title', language)}  <form onSubmit={(e) => e.preventDefault()}>
                <input
                  type="text" className='popuple-data-count-pincode' style={boxstyleqrcode}
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  placeholder="Pincode"
                />
              </form> </h2>
              <p>{translate('enter_pincode', language)}</p>
              <p>{translate('localareasectio_content', language)}</p>
            </div>
            <div className="ad-dash-s4">
            {userCount && (
              <Row>
                <Col sm={3}>
                  <div className="cor-1 females" style={boxstyle}>
                    <h2>{translate('female', language)}</h2>
                    <span>{userCount.femaleCount}</span>
                    <p>{translate('people_information1', language)} {translate('females', language)} {translate('people_information2', language)}</p>
                    <span className="cor-4 females"></span>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="cor-1 males" style={boxstyle}>
                    <h2>{translate('male', language)}</h2>
                    <span>{userCount.maleCount}</span>
                    <p>{translate('people_information1', language)} {translate('males', language)} {translate('people_information2', language)}</p>
                    <span className="cor-4 males"></span>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="cor-1 Student males_females" style={boxstyle}>
                    <h2>{translate('students', language)}</h2>
                    <span>{userCount.totalStudents}</span>
                    <p>{translate('people_information1', language)} {translate('students', language)} {translate('people_information2', language)}</p>
                    <span className="cor-4 Student males_females"></span>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="cor-1 Teacher males_females" style={boxstyle}>
                    <h2>{translate('teachers', language)}</h2>
                    <span>{userCount.totalTeachers}</span>
                    <p>{translate('people_information1', language)} {translate('teachers', language)} {translate('people_information2', language)}</p>
                    <span className="cor-4 Teacher males_females"></span>
                  </div>
                </Col>
              </Row>
                )}
            </div>
            <div className='view-more-button'>
              <Link to="/Card">
                <span className="btn-ser-need-ani Add-Any-Equation" style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>{translate('moreinformationofyourlocalarea', language)}</span>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Section