import React from 'react';
import './App.css';
import './Windows.css';
import './MacOS.css';
import './Linux.css';
import './Unknown.css';
import './index.css';
import { Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './home/Home';
import Citizencard from './citizencard/Citizencard';
import Card from './card/Card';
import Login from './login/Login';
import Dashboard from './admin/Dashboard';
import Logout from './login/Logout';
import DecodeCard from './admin/DecodeCard';
import { LanguageProvider } from './detectlanguage/LanguageContext';
import AutoLogout from './login/AutoLogout';
import AutoClearLocalStorage from './login/AutoClearLocalStorage';
import { AuthProvider } from './login/AuthContext'; 
import ProtectedRoute from './login/ProtectedRoute';
import Professioncard from './professioncard/Professioncard';
import Basicinformation from './Basicinformation';
import Yourprofession from './Yourprofession';
import Locationwisepopulation from './Locationwisepopulation';
import Enhancetransparency from './Enhancetransparency';


function App() {
  return (
    <AuthProvider>
    <LanguageProvider>
      <AutoLogout /> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/India-Citizen-Card" element={<Citizencard />} />
        <Route path="/Card" element={<Card />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/Verifications/:encryptedId" element={<DecodeCard />} />
        <Route path="/:username" element={<Dashboard />} />
        <Route path="/India-Citizen-Profession-Card" element={<Professioncard />} />
        <Route path="/Basic-Information" element={<Basicinformation />} />
        <Route path="/Your-Profession" element={<Yourprofession />} />
        <Route path="/Location-Wise-Population" element={<Locationwisepopulation />} />
        <Route path="/Enhance-Transparency" element={<Enhancetransparency />} />



        {/* admin Route */}
        <Route path="/Profile" element={<ProtectedRoute />}> 
       
        {/* <Route index element={<AdminDashboard />} /> */}
      </Route>
      {/* admin Route */}
      </Routes>
      <AutoClearLocalStorage />
    </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
