import React, { useEffect, useState, useContext } from 'react';
import { detectOS } from '../components/osDetection';
import * as colors from "../assets/css/Colors";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import Imagepng from '../components/Image_png';
import Lottie from 'lottie-react';
import Footerlottie from '../assets/json/footer.json';

// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

const Footer = () => {

    // osDetection function start
    const [os, setOs] = useState('unknown');
    useEffect(() => {
      const detectedOs = detectOS();
      setOs(detectedOs);
    }, []);
    // osDetection function End
    // Start color change auto function
  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);
// End color change auto function

// 30 secand leater open popup Start function
useEffect(() => {
    const addTimeout = setTimeout(() => {
        document.querySelector('.ani-quo').classList.add('ani-quo-act');
    }, 30000);
    const removeTimeout = setTimeout(() => {
        document.querySelector('.ani-quo').classList.remove('ani-quo-act');
    }, 60000); 
    return () => {
        clearTimeout(addTimeout);
        clearTimeout(removeTimeout);
    };
}, []); 

const [isAniActive1, setIsAniActive1] = useState(false);

const handleClick1 = () => {
    setIsAniActive1(!isAniActive1);
  };

  const handleCloseClick1 = (event) => {
    event.stopPropagation();
    setIsAniActive1(false);
  };
  // 30 secand leater open popup End function


  //  gradient color Start
  const styles = {
    background: colorScheme === 'dark' 
      ? `linear-gradient(to bottom, ${colors.theme_black_color}, ${colors.theme_white_color} 100%)` 
      : `linear-gradient(to bottom, ${colors.theme_white_color}, ${colors.theme_black_color} 100%)`,
  };

    // gradient color End

    // scroll bottom to add class function Start
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
  
        if (windowHeight + scrollTop >= documentHeight - 100) { // 10 px buffer
          setIsBottom(true);
        } else {
          setIsBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    // scroll bottom to add class function End


    // language Translate function start
    const { language, toggleLanguage } = useContext(LanguageContext);
// language Translate function end

  return (
    <div className={`${os}`}>
           <>
    <div className="ani-quo">
        <div className="ani-q1">
            <h4>What you looking for?</h4>
            <p>We connect you to service experts.</p> <span onClick={handleClick1} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Get experts</span>
        </div>
        <div className="ani-q2">
        <Imagepng text="quote" />
        </div>
    </div>
    <span className={`btn-ser-need-ani ${isBottom ? 'footer-help-support' : ''}`} onClick={handleClick1} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Help & Support</span>
    <div className={isAniActive1 ? "ani-quo-form ani-quo-form-act" : "ani-quo-form"}> <i className="material-icons ani-req-clo" onClick={handleCloseClick1}> <IoMdClose /></i>
        <div className="tit">
            <h3>What service do you need? <span>Do Digital will help you</span></h3>
        </div>
        <div className="hom-col-req">
            <div id="home_slide_enq_success" className="log" style={{display: 'none'}}>
                <p>Your Enquiry Is Submitted Successfully!!!</p>
            </div>
            <div id="home_slide_enq_fail" className="log" style={{display: 'none'}}>
                <p>Something Went Wrong!!!</p>
            </div>
            <div id="home_slide_enq_same" className="log" style={{display: 'none'}}>
                <p>You cannot make enquiry on your own listing</p>
            </div>
            <form name="home_slide_enquiry_form" id="home_slide_enquiry_form" method="post">
                <input type="hidden" className="form-control" name="listing_id" placeholder="" required/>
                <input type="hidden" className="form-control" name="listing_user_id" placeholder="" required/>
                <input type="hidden" className="form-control" name="enquiry_sender_id" placeholder="" required/>
                <input type="hidden" className="form-control" name="enquiry_source" placeholder="" required/>
                <div className="form-group">
                    <input type="text" name="enquiry_name" required="required" className="form-control"
                        placeholder="Enter name*"/>
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" placeholder="Enter email*" required="required"
                        name="enquiry_email"
                        pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$"
                        title="Invalid email address"/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" name="enquiry_mobile"
                        placeholder="Enter mobile number *" pattern="[7-9]{1}[0-9]{9}"
                        title="Phone number starting with 7-9 and remaing 9 digit with 0-9" required=""/>
                </div>
                <div className="form-group">
                    <select name="enquiry_category" id="enquiry_category" className="form-control">
                        <option>Select Category</option>
                    </select>
                </div>
                <div className="form-group">
                    <textarea className="form-control" rows="3" name="enquiry_message"
                        placeholder="Enter your query or message"></textarea>
                </div>
                <input type="hidden" id="source"/>
                <button type="submit" id="home_slide_enquiry_submit" name="home_slide_enquiry_submit"
                    className="btn btn-primary">Submit Requirements</button>
            </form>
        </div>
    </div>
    <section>
        <div className="full-bot-book">
            <Container>
                <Row>
                    <div className="bot-book" style={styles}>
                        <Col sm={2} className='bb-img'>
                        <Lottie animationData={Footerlottie} loop={true} />
                        </Col>
                        <Col sm={7} className='bb-text'>
                            <h4>{translate('indiancitizencard', language)} </h4>
                            <p> {translate('footer_help_box', language)}</p>
                        </Col>
                        <Col sm={3} className='bb-link'>
                       
                        <span className="btn-ser-need-ani Add-Any-Equation" onClick={handleClick1} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>{translate('addanyequation', language)} </span>
                        </Col>
                    </div>
                    </Row>
                </Container>
        </div>
    </section>
    <section className=" wed-hom-footer">
        <Container>
            <Row className='foot-supp'>
                <h2><span>Email:</span> dodigital@gmail.com</h2>
             </Row>
            </Container>
    </section>
    <section>
        <div className="cr"  style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
        <Container>
                <Row>
                    <p>Copyright © 2024-2025 <Link to="ohackerji.com" target="_blank">OHackerji</Link>. Proudly
                        powered by <Link to="" target="_blank">dodigitals.in</Link>
                    </p>
               </Row>
               </Container>
        </div>
    </section>
    <div className="fqui-menu" style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
        <ul>
            <li>
                <Link to="-">
                    <Imagepng text="home" />Home</Link>
            </li>
            <li>
                <Link to="/India-Citizen-Card" className="act">
                <Imagepng text="reading" />{translate('indiancitizencard', language)}</Link>
            </li>
            <li>
                <Link to="/India-Citizen-Profession-Card" className="act">
                <Imagepng text="teacher" />{translate('indiancitizenprofessioncard', language)}</Link>
            </li>
                    <li>
                        <div>
                    <Imagepng text="reading" />
                    <button
                          onClick={toggleLanguage}
                        >
                          {translate('switchLanguage', language)}
                        </button>
                        </div>
                    </li>
        </ul>
    </div>
   </>
    </div>
  )
}

export default Footer