// Location.js
import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as colors from "../assets/css/Colors";
import { getGeocodeFromCoordinates, getGeocodeFromPincode } from '../components/googleMapsAPI';

// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

const Location = ({ onLocationUpdate }) => {
  const { language } = useContext(LanguageContext);

  const [location, setLocation] = useState({ lat: null, lng: null, name: '' });
  const [pincode, setPincode] = useState('');
  const [error, setError] = useState('');

  const fetchLiveLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude, name: '' });
          try {
            const address = await getGeocodeFromCoordinates(latitude, longitude);
            setLocation((prevState) => ({ ...prevState, name: address }));
            onLocationUpdate(address); // Update the location in the parent component
          } catch (error) {
            setError('Error fetching address from coordinates');
          }
        },
        (error) => {
          console.error("Error obtaining location", error);
          setError('Error obtaining location');
        }
      );
    } else {
      console.error("Geolocation not available");
      setError('Geolocation not available');
    }
  };

  useEffect(() => {
    fetchLiveLocation();
  }, []);

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };

  const handlePincodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const { lat, lng, address } = await getGeocodeFromPincode(pincode);
      setLocation({ lat, lng, name: address });
      onLocationUpdate(address); // Update the location in the parent component
    } catch (error) {
      console.error("Error fetching location for pincode", error);
      setError('Error fetching location for pincode');
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return (
    <>
      <Button onClick={handleShow} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
        <p style={{ color: colorScheme === 'dark' ? colors.white : colors.white }}> {translate('location', language)}</p>
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className='modal_location_title'>{translate('modal_location_title', language)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {location.lat && location.lng ? (
              <p>{translate('chose_location', language)}: {location.name}</p>
            ) : (
              <p>Fetching your location...</p>
            )}
            {error && <p className='error'>{error}</p>}
            <form onSubmit={handlePincodeSubmit} className='location_pincode'>
              <label>
              {translate('pincode', language)}:
                <input type="text" value={pincode} onChange={handlePincodeChange} />
              </label>
              <button type="submit" style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color, border: 'none' }}>Submit</button>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={fetchLiveLocation} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color, border: 'none' }}>Fetch Live Location</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Location;
