import React, { useEffect, useRef } from 'react';
import { logoutUser } from '../components/Api';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Imagepng from '../components/Image_png';

const Logout = () => {
  const navigate = useNavigate();
  const logoutButtonRef = useRef(null); 

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        await logoutUser(token);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (logoutButtonRef.current) {
        logoutButtonRef.current.click(); // Automatically click the button
      }
    }, 10 * 60 * 1000); // 60 seconds

    return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
  }, []); // Empty dependency array ensures this runs only once

  return (
    
      <li>
                            <Link ref={logoutButtonRef} onClick={handleLogout}>
                              <Imagepng text={'dbl12'} />
                              Log Out
                            </Link>
                          </li>
  );
};

export default Logout;