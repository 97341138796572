import React from 'react';
import Header from '../layout/Header';
import Hero from './Hero';
import Cardsectionabout from './Cardsectionabout';
import Section from './Section';
import Homeadsslider from './Homeadsslider';
import Aboutsectionhomepage from './Aboutsectionhomepage';
import Footer from '../layout/Footer';

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Cardsectionabout />
      <Section />
      <Homeadsslider />
      <Aboutsectionhomepage />
      <Footer />
    </div>
  )
}

export default Home