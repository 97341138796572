import React, { useEffect, useState, useContext } from "react";
import './card.css';
import Header from '../layout/Header';
import Homeadsslider from '../home/Homeadsslider';
import Aboutsectionhomepage from '../home/Aboutsectionhomepage';
import Footer from '../layout/Footer';
import Imagepng from '../components/Image_png';
// 
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap';
import * as colors from "../assets/css/Colors";
import Darkimg from '../assets/img/curve2.png';
import whiteimg from '../assets/img/curve1.png';

import Darkbg from '../assets/img/card-dark.webp';
import whitebg from '../assets/img/card-light.webp';

// api
import { getUserCount } from '../components/Api';
import Location from '../modal/Location';

// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";




const AGE_RANGES = [
    { min: 0, max: 5, label: '0 - 5 Years' },
    { min: 6, max: 6, label: '6 Years' },
    { min: 7, max: 7, label: '7 Years' },
    { min: 8, max: 8, label: '8 Years' },
    { min: 9, max: 9, label: '9 Years' },
    { min: 10, max: 10, label: '10 Years' },
    { min: 11, max: 11, label: '11 Years' },
    { min: 12, max: 12, label: '12 Years' },
    { min: 13, max: 13, label: '13 Years' },
    { min: 14, max: 14, label: '14 Years' },
    { min: 15, max: 15, label: '15 Years' },
    { min: 16, max: 16, label: '16 Years' },
    { min: 17, max: 17, label: '17 Years' },
    { min: 18, max: 18, label: '18 Years' },
    { min: 19, max: 19, label: '19 Years' },
    { min: 20, max: 20, label: '20 Years' },
    { min: 21, max: 21, label: '21 Years' },
    { min: 22, max: 22, label: '22 Years' },
    { min: 23, max: 23, label: '23 Years' },
    { min: 24, max: 24, label: '24 Years' },
    { min: 25, max: 25, label: '25 Years' },
    { min: 26, max: 26, label: '26 Years' },
    { min: 27, max: 27, label: '27 Years' },
    { min: 28, max: 28, label: '28 Years' },
    { min: 29, max: 29, label: '29 Years' },
    { min: 30, max: 30, label: '30 Years' },
    { min: 31, max: 31, label: '31 Years' },
    { min: 32, max: 32, label: '32 Years' },
    { min: 33, max: 33, label: '33 Years' },
    { min: 34, max: 34, label: '34 Years' },
    { min: 35, max: 35, label: '35 Years' },
    { min: 36, max: Infinity, label: 'Over 36 Years' }
];


const Card = () => {
    const [userCount, setUserCount] = useState(null);
    const [initialUserCount, setInitialUserCount] = useState(null);
    const [pincode, setPincode] = useState('');
    const [error, setError] = useState(null);

    const fetchUserCount = async (zipcode) => {
        try {
            const data = await getUserCount(zipcode); // Replace with your API call
            return data.data;
        } catch (error) {
            throw new Error('Failed to fetch user count');
        }
    };

    const handleLocationUpdate = async (updatedLocation) => {
        try {
            const pincodeMatch = updatedLocation.match(/\b\d{6}\b/);
            const newPincode = pincodeMatch ? pincodeMatch[0] : '';
            setPincode(newPincode);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleSubmit = async () => {
        if (pincode.length === 6) {
            try {
                const data = await fetchUserCount(pincode);
                setUserCount(data);
            } catch (error) {
                setError(error.message);
            }
        }
    };

    useEffect(() => {
        const fetchInitialUserCount = async () => {
            try {
                const data = await fetchUserCount(null); // Initial fetch with no pincode
                setInitialUserCount(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchInitialUserCount();
    }, []);

    useEffect(() => {
        if (pincode.length === 6) {
            handleSubmit();
        }
    }, [pincode]);

    // Ensure userCount is available before accessing its properties
    const agewise_report = Array.isArray(userCount?.agewise_report) ? userCount.agewise_report : [];
    const initialAgewiseReport = Array.isArray(initialUserCount?.agewise_report) ? initialUserCount.agewise_report : [];


    const calculateTotal = (field, report) => {
        return report.reduce((sum, entry) => sum + (entry[field] || 0), 0);
    };

    // const filterAgeData = (report, minAge, maxAge, field) => {
    //     return report.filter(({ age }) => age >= minAge && age <= maxAge)
    //         .reduce((sum, entry) => sum + (entry[field] || 0), 0);
    // };


    // Utility function to filter age data
    const filterAgeData = (report, minAge, maxAge, field) => {
        if (!Array.isArray(report)) {
            console.error('Expected report to be an array, but got:', report);
            return 0;
        }
        return report.filter(({ age }) => age >= minAge && age <= maxAge)
            .reduce((sum, entry) => sum + (entry[field] || 0), 0);
    };


    const renderAgeRangeData = (gender, report) => (
        AGE_RANGES.map((range, index) => {
            const count = filterAgeData(report, range.min, range.max, `total_${gender}`);
            return <AgeRangeComponent key={index} label={range.label} value={count} gender={gender} role='' />;
        })
    );

    const renderAgeRangeTotalData = (report) => (
        AGE_RANGES.map((range, index) => {
            const maleCount = filterAgeData(report, range.min, range.max, 'total_males');
            const femaleCount = filterAgeData(report, range.min, range.max, 'total_females');
            const totalCount = maleCount + femaleCount;
            return <AgeRangeComponent key={index} label={range.label} value={totalCount} gender='All' role='' />;
        })
    );

    const renderRoleRangeData = (role, gender, report) => (
        AGE_RANGES.map((range, index) => {
            const count = filterAgeData(report, range.min, range.max, `${role}_${gender}`);
            return <AgeRangeComponent key={index} label={range.label} value={count} gender={gender} role={role} />;
        })
    );

    const renderRoleRangeTotalData = (role, report) => (
        AGE_RANGES.map((range, index) => {
            const maleCount = filterAgeData(report, range.min, range.max, `${role}_males`);
            const femaleCount = filterAgeData(report, range.min, range.max, `${role}_females`);
            const totalCount = maleCount + femaleCount;
            return <AgeRangeComponent key={index} label={range.label} value={totalCount} gender='All' role={role} />;
        })
    );

    const totalStudentsMales = calculateTotal('student_males', agewise_report);
    const totalStudentsFemales = calculateTotal('student_females', agewise_report);
    const totalTeachersMales = calculateTotal('teacher_males', agewise_report);
    const totalTeachersFemales = calculateTotal('teacher_females', agewise_report);
    const totalStudents = totalStudentsMales + totalStudentsFemales;
    const totalTeachers = totalTeachersMales + totalTeachersFemales;
    const totalMales = calculateTotal('total_males', agewise_report);
    const totalFemales = calculateTotal('total_females', agewise_report);
    const grandTotal = totalMales + totalFemales;

    const totalStudentsMalesInitial = calculateTotal('student_males', initialAgewiseReport);
    const totalStudentsFemalesInitial = calculateTotal('student_females', initialAgewiseReport);
    const totalTeachersMalesInitial = calculateTotal('teacher_males', initialAgewiseReport);
    const totalTeachersFemalesInitial = calculateTotal('teacher_females', initialAgewiseReport);
    const totalStudentsInitial = totalStudentsMalesInitial + totalStudentsFemalesInitial;
    const totalTeachersInitial = totalTeachersMalesInitial + totalTeachersFemalesInitial;
    const totalMalesInitial = calculateTotal('total_males', initialAgewiseReport);
    const totalFemalesInitial = calculateTotal('total_females', initialAgewiseReport);
    const grandTotalInitial = totalMalesInitial + totalFemalesInitial;


    // Reusable component for rendering age range data
    const AgeRangeComponent = ({ label, value, gender, role }) => (
        <Col sm={3}>
            <div className={`cor-1 ${role.charAt(0).toUpperCase() + role.slice(1)} ${gender === 'males' ? 'males' : gender === 'females' ? 'females' : 'males_females'}`} style={boxstyle}>
                <h4>{label}</h4>
                <h2>{translate(gender, language)} {translate(role, language)} </h2>
                <span>{value}</span>
                <p>{translate('total_populations_1', language)} {translate(gender, language)} {translate(role, language)} {translate('total_populations_2', language)}</p>
                <span className={`cor-4 ${role.charAt(0).toUpperCase() + role.slice(1)} ${gender === 'males' ? 'males' : gender === 'females' ? 'females' : 'males_females'}`}></span>
            </div>
        </Col>
    );

    const calculateTotals = (report, role, gender) => {
        return AGE_RANGES.map((range, index) => {
            const maleCount = filterAgeData(report, range.min, range.max, `${role}_males`);
            const femaleCount = filterAgeData(report, range.min, range.max, `${role}_females`);
            const totalCount = maleCount + femaleCount;
            return (
                <Col sm={3} key={index}>
                    <div className={`cor-1 ${role.charAt(0).toUpperCase() + role.slice(1)} ${gender === 'males' ? 'males' : gender === 'females' ? 'females' : 'males_females'}`} style={boxstyle}>
                        <h4>{range.label}</h4>
                        <h2>{translate(gender, language)} {translate(role, language)}</h2>
                        <span>{`${totalCount}`}</span>
                         <p>{translate('total_populations_1', language)} {translate(gender, language)} {translate(role, language)} {translate('total_populations_2', language)}</p>
                        <span className={`cor-4 ${role.charAt(0).toUpperCase() + role.slice(1)} ${gender === 'males' ? 'males' : gender === 'females' ? 'females' : 'males_females'}`}></span>
                    </div>
                </Col>
            );
        });
    };


    // end






    // Define image URLs and color dark mode and light mode change image start funtion
    const lightImageURL = whiteimg;
    const darkImageURL = Darkimg;

    const lightBgURL = whitebg;
    const darkBgURL = Darkbg;

    const [colorScheme, setColorScheme] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleChange = (e) => {
            setColorScheme(e.matches ? 'dark' : 'light');
        };

        mediaQuery.addEventListener('change', handleChange);

        return () => {
            mediaQuery.removeEventListener('change', handleChange);
        };
    }, []);

    useEffect(() => {
        const element = document.querySelector(".ban-short-links");
        if (element) {
            element.classList.add("ani");
        }
    }, []);
    // Define image URLs and color dark mode and light mode change image end funtion

    //  gradient color Start
    const styles = {
        background: colorScheme === 'dark'
            ? `linear-gradient(to right, ${colors.theme_black_color}, ${colors.theme_black_color}, ${colors.theme_white_color})`
            : `linear-gradient(to right, ${colors.theme_white_color}, ${colors.theme_white_color}, ${colors.theme_black_color})`,
    };

    const boxstyle = {
        backgroundColor: colorScheme === 'dark' ? colors.theme_black_color_bg : colors.theme_white_color_bg,
        border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
    }

    // gradient color End
    // tab function start
    const [activeTab, setActiveTab] = useState('localareapopulation');
    const [hoverTab, setHoverTab] = useState(null);
    const [activeNestedTabs, setActiveNestedTabs] = useState({
        localareapopulation: 'localareapopulation_males',
        indiapopulation: 'indiapopulation_males',
    });
    const [hoverNestedTab, setHoverNestedTab] = useState(null);
    const getTabStyle = (tabKey) => {
        if (activeTab === tabKey) {
            return {
                background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color,
                color: 'white',
                border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        } else if (hoverTab === tabKey) {
            return {
                background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color,
                color: 'white',
                border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        } else {
            return {
                backgroundColor: colorScheme === 'dark' ? colors.theme_black_color_bg : colors.theme_white_color_bg,
                color: 'black',
                border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        }
    };

    const getNestedTabStyle = (tabKey, parentTabKey) => {
        if (activeNestedTabs[parentTabKey] === tabKey) {
            return {
                background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color,
                color: 'white',
                border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        } else if (hoverNestedTab === tabKey) {
            return {
                background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color,
                color: 'white',
                border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        } else {
            return {
                backgroundColor: colorScheme === 'dark' ? colors.theme_black_color_bg : colors.theme_white_color_bg,
                color: 'black',
                border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        }
    };

    const getHeadingText = () => {
        switch (activeTab) {
            case 'localareapopulation':
                return (
                    <>
                        {translate('your_local_area1', language)}
                        <input type="text"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            placeholder="Pincode" className='popuple-data-count-pincode' style={boxstyleqrcode} />
                        &nbsp;{translate('your_local_area2', language)}
                    </>
                );
            case 'indiapopulation':
                return `${translate('india_population1', language)}`;
            default:
                return '';
        }
    };

    const handleNestedTabSelect = (parentTabKey, tabKey) => {
        setActiveNestedTabs((prevState) => ({
            ...prevState,
            [parentTabKey]: tabKey,
        }));
    };
    // tab function end

    // color function start

    const boxstyleqrcode = {
        border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
        borderRadius: '8px',
    };

    // color function end

    // language Translate function start
    const { language } = useContext(LanguageContext);

    // language Translate function end


    return (
        <div>
            <Header />
            <div className='loctionbuttonhide'>
                <Location onLocationUpdate={handleLocationUpdate} />
            </div>
            <div className="str ind2-home">
                <div className="hom-head" style={{ backgroundImage: `url(${colorScheme === 'light' ? lightBgURL : darkBgURL})` }}>
                    <Container>
                        <Row>
                            <div className="ban-tit">
                                <h1>
                                    <b> {translate('card_page_top_heading1', language)} <span style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}> {translate('card_page_top_heading2', language)}<img src={colorScheme === 'light' ? lightImageURL : darkImageURL} alt="sharp" /></span> {translate('card_page_top_heading3', language)}
                                        <span style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}> {translate('card_page_top_heading4', language)}<img src={colorScheme === 'light' ? lightImageURL : darkImageURL} alt="sharp" /></span> {translate('card_page_top_heading5', language)}
                                    </b>
                                    <br></br>
                                    <span style={{ lineHeight: '32px' }}>
                                        {translate('card_page_top_description', language)}
                                    </span>
                                </h1>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="ban-ql">
                <Container>
                    {error && <div>Error: {error}</div>}
                    {!initialUserCount ? (
                        <div>Loading initial data...</div>
                    ) : (
                        <Row>
                            <Col sm={3} className="mb-3">
                                <div className='card-box-home' style={boxstyle}>
                                    <Imagepng text={'totle-people'} />
                                    <h4>{translate('female', language)}</h4>
                                    <h4>{initialUserCount.femaleCount}</h4>
                                    <p> {translate('total_people_information1', language)}  {translate('females', language)}  {translate('total_people_information2', language)}</p>
                                </div>
                            </Col>
                            <Col sm={3} className="mb-3">
                                <div className='card-box-home' style={boxstyle}>
                                    <Imagepng text={'totle-male'} />
                                    <h4>{translate('male', language)}</h4>
                                    <h4>{initialUserCount.maleCount}</h4>
                                    <p> {translate('total_people_information1', language)}  {translate('males', language)}  {translate('total_people_information2', language)}</p>
                                </div>
                            </Col>
                            <Col sm={3} className="mb-3">
                                <div className='card-box-home' style={boxstyle}>
                                    <Imagepng text={'totle-student'} />
                                    <h4>{translate('students', language)}</h4>
                                    <h4>{initialUserCount.totalStudents}</h4>
                                    <p> {translate('total_people_information1', language)}  {translate('students', language)}  {translate('total_people_information2', language)}</p>
                                </div>
                            </Col>
                            <Col sm={3} className="mb-3">
                                <div className='card-box-home' style={boxstyle}>
                                    <Imagepng text={'totle-teacher'} />
                                    <h4>{translate('teachers', language)}</h4>
                                    <h4>{initialUserCount.totalTeachers}</h4>
                                    <p> {translate('total_people_information1', language)}  {translate('teachers', language)}  {translate('total_people_information2', language)}</p>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
            <section className='section'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="ad-dash-s1">
                                <div className="cd-cen-intr-inn" style={styles}>
                                    <h2><b>{translate('welcome_card', language)}</b></h2>
                                    <p>{translate('welcome_card_p', language)}</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="home-tit">
                                <h2><div> {getHeadingText()}</div></h2>
                                <p>{translate('card_page_top_description', language)}</p>
                            </div>
                            <div className="tab-population-local-teacher-stude">
                                <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                                    <Nav variant="pills" className="tab-population-local-teacher-stude-heading-button">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="localareapopulation"
                                                style={getTabStyle('localareapopulation')}
                                                onMouseEnter={() => setHoverTab('localareapopulation')}
                                                onMouseLeave={() => setHoverTab(null)}
                                            >
                                                {translate('local_area_population', language)}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="indiapopulation"
                                                style={getTabStyle('indiapopulation')}
                                                onMouseEnter={() => setHoverTab('indiapopulation')}
                                                onMouseLeave={() => setHoverTab(null)}
                                            >
                                                {translate('india_population', language)}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="localareapopulation">
                                            <Tab.Container
                                                id="nested-tabs-example"
                                                activeKey={activeNestedTabs.localareapopulation}
                                                onSelect={(k) => handleNestedTabSelect('localareapopulation', k)}
                                            >
                                                <Nav variant="pills" className="tab-population-local-teacher-stude-heading-button sub-tabs">
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_males"
                                                            style={getNestedTabStyle('localareapopulation_males', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_males')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('male', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_females"
                                                            style={getNestedTabStyle('localareapopulation_females', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_females')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('female', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_malesfemales"
                                                            style={getNestedTabStyle('localareapopulation_malesfemales', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_malesfemales')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('populations', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_malesteachers"
                                                            style={getNestedTabStyle('localareapopulation_malesteachers', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_malesteachers')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('male', language)} {translate('teachers', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_femalesteachers"
                                                            style={getNestedTabStyle('localareapopulation_femalesteachers', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_femalesteachers')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('female', language)} {translate('teachers', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_malesfemalesteachers"
                                                            style={getNestedTabStyle('localareapopulation_malesfemalesteachers', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_malesfemalesteachers')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('teachers', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_malesstudents"
                                                            style={getNestedTabStyle('localareapopulation_malesstudents', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_malesstudents')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('male', language)} {translate('students', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_femalesstudents"
                                                            style={getNestedTabStyle('localareapopulation_femalesstudents', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_femalesstudents')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('female', language)} {translate('students', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_malesfemalesstudents"
                                                            style={getNestedTabStyle('localareapopulation_malesfemalesstudents', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_malesfemalesstudents')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('students', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="localareapopulation_totals"
                                                            style={getNestedTabStyle('localareapopulation_totals', 'localareapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('localareapopulation_totals')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('totals', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="localareapopulation_males">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderAgeRangeData('males', agewise_report)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_females">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderAgeRangeData('females', agewise_report)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_malesfemales">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderAgeRangeTotalData(agewise_report)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_malesteachers">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('teacher', 'males', agewise_report)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_femalesteachers">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('teacher', 'females', agewise_report)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_malesfemalesteachers">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {calculateTotals(agewise_report, 'teacher')}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_malesstudents">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('student', 'males', agewise_report)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_femalesstudents">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('student', 'females', agewise_report)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_malesfemalesstudents">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {calculateTotals(agewise_report, 'student')}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="localareapopulation_totals">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Student males" style={boxstyle}>
                                                                        <h2>{translate('male', language)} {translate('students', language)}</h2>
                                                                        <span>{totalStudentsMales}</span>
                                                                        <p> {translate('total_populations_1', language)} {translate('male', language)} {translate('students', language)}   {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Student males"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Student females" style={boxstyle}>
                                                                    <h2> {translate('female', language)} {translate('students', language)}</h2>
                                                                        <span>{totalStudentsFemales}</span>
                                                                        <p> {translate('total_populations_1', language)} {translate('female', language)} {translate('students', language)}   {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Student females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Teacher males" style={boxstyle}>
                                                                    <h2>{translate('male', language)} {translate('teachers', language)}</h2>
                                                                        <span>{totalTeachersMales}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('male', language)} {translate('teachers', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Teacher males"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Teacher females" style={boxstyle}>
                                                                    <h2>{translate('female', language)} {translate('teachers', language)}</h2>
                                                                        <span>{totalTeachersFemales}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('female', language)} {translate('teachers', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Teacher females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 males" style={boxstyle}>
                                                                    <h2>{translate('males', language)}</h2>
                                                                        <span>{totalMales}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('males', language)} {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 males"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 females" style={boxstyle}>
                                                                    <h2>{translate('females', language)}</h2>
                                                                        <span>{totalFemales}</span>
                                                                        <p> {translate('total_populations_1', language)} {translate('females', language)} {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 males_females" style={boxstyle}>
                                                                    <h2>{translate('populations', language)}</h2>
                                                                        <span>{grandTotal}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('all', language)} {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 males_females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Student males_females" style={boxstyle}>
                                                                        <h2>{translate('students', language)}</h2>
                                                                        <span>{totalStudents}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('students', language)} {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Student males_females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Teacher males_females" style={boxstyle}>
                                                                        <h2>{translate('teachers', language)}</h2>
                                                                        <span>{totalTeachers}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('teachers', language)} {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Teacher males_females"></span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="indiapopulation">
                                            <Tab.Container
                                                id="nested-tabs-example"
                                                activeKey={activeNestedTabs.indiapopulation}
                                                onSelect={(k) => handleNestedTabSelect('indiapopulation', k)}
                                            >
                                                <Nav variant="pills" className="tab-population-local-teacher-stude-heading-button sub-tabs">
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_males"
                                                            style={getNestedTabStyle('indiapopulation_males', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_males')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('male', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_females"
                                                            style={getNestedTabStyle('indiapopulation_females', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_females')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                             {translate('female', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_malesfemales"
                                                            style={getNestedTabStyle('indiapopulation_malesfemales', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_malesfemales')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                            {translate('populations', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_malesteachers"
                                                            style={getNestedTabStyle('indiapopulation_malesteachers', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_malesteachers')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                           {translate('male', language)} {translate('teachers', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_femalesteachers"
                                                            style={getNestedTabStyle('indiapopulation_femalesteachers', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_femalesteachers')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                           {translate('female', language)} {translate('teachers', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_malesfemalesteachers"
                                                            style={getNestedTabStyle('indiapopulation_malesfemalesteachers', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_malesfemalesteachers')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                           {translate('teachers', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_malesstudents"
                                                            style={getNestedTabStyle('indiapopulation_malesstudents', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_malesstudents')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                           {translate('male', language)} {translate('students', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_femalesstudents"
                                                            style={getNestedTabStyle('indiapopulation_femalesstudents', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_femalesstudents')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                           {translate('female', language)} {translate('students', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_malesfemalesstudents"
                                                            style={getNestedTabStyle('indiapopulation_malesfemalesstudents', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_malesfemalesstudents')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                           {translate('students', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="indiapopulation_totals"
                                                            style={getNestedTabStyle('indiapopulation_totals', 'indiapopulation')}
                                                            onMouseEnter={() => setHoverNestedTab('indiapopulation_totals')}
                                                            onMouseLeave={() => setHoverNestedTab(null)}
                                                        >
                                                           {translate('totals', language)}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="indiapopulation_males">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderAgeRangeData('males', initialAgewiseReport)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_females">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderAgeRangeData('females', initialAgewiseReport)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_malesfemales">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderAgeRangeTotalData(initialAgewiseReport)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_malesteachers">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('teacher', 'males', initialAgewiseReport)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_femalesteachers">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('teacher', 'females', initialAgewiseReport)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_malesfemalesteachers">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {calculateTotals(initialAgewiseReport, 'teacher')}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_malesstudents">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('student', 'males', initialAgewiseReport)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_femalesstudents">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {renderRoleRangeData('student', 'females', initialAgewiseReport)}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_malesfemalesstudents">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                {calculateTotals(initialAgewiseReport, 'student')}
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="indiapopulation_totals">
                                                        <div className="ad-dash-s4">
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Student males" style={boxstyle}>
                                                                        <h2>{translate('male', language)} {translate('students', language)}</h2>
                                                                        <span>{totalStudentsMalesInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('male', language)} {translate('students', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Student males"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Student females" style={boxstyle}>
                                                                    <h2>{translate('female', language)} {translate('students', language)}</h2>
                                                                        <span>{totalStudentsFemalesInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('female', language)} {translate('students', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Student females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Teacher males" style={boxstyle}>
                                                                    <h2>{translate('male', language)} {translate('teachers', language)}</h2>
                                                                        <span>{totalTeachersMalesInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('male', language)} {translate('teachers', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Teacher males"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Teacher females" style={boxstyle}>
                                                                    <h2>{translate('female', language)} {translate('teachers', language)}</h2>
                                                                        <span>{totalTeachersFemalesInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('female', language)} {translate('teachers', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Teacher females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 males" style={boxstyle}>
                                                                    <h2>{translate('males', language)}</h2>
                                                                        <span>{totalMalesInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('males', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 males"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 females" style={boxstyle}>
                                                                    <h2>{translate('females', language)}</h2>
                                                                        <span>{totalFemalesInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('females', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 males_females" style={boxstyle}>
                                                                    <h2>{translate('populations', language)}</h2>
                                                                        <span>{grandTotalInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('all', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 males_females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Student males_females" style={boxstyle}>
                                                                    <h2>{translate('students', language)}</h2>
                                                                        <span>{totalStudentsInitial}</span>
                                                                        <p> {translate('total_populations_1', language)} {translate('students', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Student males_females"></span>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="cor-1 Teacher males_females" style={boxstyle}>
                                                                    <h2>{translate('teachers', language)}</h2>
                                                                        <span>{totalTeachersInitial}</span>
                                                                        <p>{translate('total_populations_1', language)} {translate('teachers', language)}  {translate('total_populations_2', language)}</p>
                                                                        <span className="cor-4 Teacher males_females"></span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Homeadsslider />
            <Aboutsectionhomepage />
            <Footer />
        </div>
    )
}

export default Card