const translations = {
  en: {
    do_digitals:"Do Digitals",
    female:"Female",
    male:"Male",
    student:"Student",
    teacher:"Teacher",
    location: "Location",
    chose_location:"Location",
    modal_location_title:"Which location's information would you like to know?",
    switchLanguage: "Switch to Hindi",
    pincode:"Enter Pincode",
    createcard:"Identify yourself with",
    citizencard:" Indian Citizen Card",
    india_citizen_profession_card:"India Citizen Profession Card",
    herosectioncontent:"Do digital Indian Citizen Card make it easier to your identity and your Professional details",
    homepoint1:"Basic information/data",
    homepoint2:"Your Profession/Skill/Education",
    homepoint3:"Location Wise Population",
    homepoint4:" Enhance Transparency",
    homepoint1_1:"It provides your basic information Like Profile photo, Name and I'd number. We will not interrupt your private information.",
    homepoint2_2:"With the help of Do Digital Indian Citizen Card, Everyone Make sure their current occupation, previous experience, education and achievements.",
    homepoint3_3:"With the help of do digital Indian citizen card,  we can find out number of peoples accordingly their local areas.",
    homepoint4_4:"With this Card, We can understand the people their online data and information is safe, secure and what's happening their data openly and honestly.",
    localareasectio_title_span:"Detailed Information about People",
    localareasectio_title:"of local Area",
    localareasectio_content:"This information relates to the number of people including students, teachers, babies, men, women by age groups.",
    localareasectio_box_1:"With the help of this Card, you will know number of females in your local area.",
    localareasectio_box_2:"With the help of this Card you will know number of males in your local area.",
    localareasectio_box_3:"With the help of this Card, you will know number of students in your local area.",
    localareasectio_box_4:"With the help of this Card, you will know number of teachers in your local area.",
    about_section_1:"Introducing Do Digital Indian Citizen Card, to know whole information and professional details for every Indian Citizen.",
    about_section_2:"With the help of this Card, We can know the Candidate's State, District and specific area of residence, We are very sensible about your privacy and your private details you do not worried about that, We need only your basic information because it's helpful to access all government services, benefits and Take advantages for all.",
    about_section_3:"This Card is next step of Indian Citizen Card , you will know all information as much as provided in Indian citizen Card, You would have your professional identity which is essential for jobs application, business and networking Ultimate you would have your profession Status with the form of this card.",
    about_section_4:"Hopefully, you find your Life better than previous, ahead. And you will enjoy the benefits and easiness of these Both cards your profession as well as your future. Let's spread Awareness and transparency about this 'Do digital Indian Citizen Card' in India As well as entire World.",
    welcome_card:"Hi Welcome To Do Digital Indian Citizen Card",
    welcome_card_p:"Do Digital India Citizen Cards help you gather detailed information about the population in your location, including the number of students, teachers, and people of all age groups. You can find out the total population, the count of men, women, and children, and their respective age distributions in your area.",
    explorenow:"Explore Now",
    moreinformationofyourlocalarea:"More Information of Your Local Area",
    indiancitizencard:"Indian Citizen Card",
    indiancitizenprofessioncard:"Indian Citizen Profession Card",
    ad_box_p1:"This card allows anyone to quickly identify the cardholder's state, district, and specific area of residence",
    ad_box_p2:"Building upon the features of the India Citizen Card, this card also provides detailed professional information about the cardholder",
    footer_help_box:"This card helps anyone to quickly identify the candidate's State, District  and Specific area of residence.",
    addanyequation:"Add Any Equation",
    login_heading:"Are you an",
    login_heading_span:"Indian ?",
    login_user:"Login User",
    enter_pincode:"Which local area's information would you like to know? Enter the pincode of that local area where a pincode (6 digits) is already available.",
    people_information1:"The total number of",
    people_information2:"in the local area for the pincode you entered.",
    // females:"Females",
    // males:"Males",
    females:"Female",
    males:"Male",
    students:"Students",
    teachers:"Teachers",
    card_page_top_heading1:"Information about the population of",
    card_page_top_heading2:"your local area",
    card_page_top_heading3:"and the total population",
    card_page_top_heading4:"of India",
    card_page_top_heading5:".",
    card_page_top_description:"Along with the total population of your area, you can also find information about the number of men, women, and children in the total population of India, as well as their age distribution.",
    total_people_information1:"The total number of",
    total_people_information2:"in the total population of India",
    information_of:"Information of",
    your_local_area:"Your Local Area",
    population:"Population",
    india:"India",
    populations:"Total Population",
    totals:"Totals",
    males_females:"Total Population",
    local_area_population:"Total population of local area",
    india_population:"Total population of India",
    total_populations:"Total Population",
    your_local_area1:"Total population of your local area",
    your_local_area2:".",
    india_population1:"Information of total population of India.",
    total_populations_1:"' Total population of",
    total_populations_2:"'",
    All:"All",
    profile_image:"Profile Image",
    first_name:"First Name",
    last_name:"Last Name",
    username:"Username",
    email:"Email",
    mobile_number:"Mobile Number",
    gender:"Gender",
    date_of_birth:"Date of Birth",
    permanent_address:"Permanent Address",
    state:"State",
    district:"District",
    rto_number:"RTO Number",
    zip:"Zip",
    caste_certificate:"Caste Certificate",
    original_residence:"Original Residence",
    pan_card:"PAN Card",
    aadhar_card:"Aadhar Card",
    latitude:"Latitude",
    longitude:"Longitude",
    enter_your:"Enter Your",
    enter_your1:".",
    enter_your2:"Enter Your",
    please_select1:"Please select",
    please_select2:".",
    other:"Other",
    submit:"Submit",
    creactdodigitalcard:"Don’t have a Do Digital Indian Citizen Card? Haven’t created your Do Digital Indian Citizen Card yet? Then you can create your Do Digital Indian Citizen Card from here. It takes less than a minute.",
    creactdodigitalcard_page_description:"This card helps quickly find out where you live, including your state, district and area. it is a complete identity document that helps you easily access government services and benefits and supports National unity by making things work better. this card makes it easy to find out exactly where you are located in India.",
    india_citizen_profession_card_description:"In this Card, you will get already all information and functions as much as provided in Indian citizen card, Apart that you would have your working identity like your profession card with the help of this profession card you are present in front of government, you will get all kind of benefits from Government and also make easier to get job in private sector by which your status would be always active in your career.",
    indiacitizenprofessioncard:"Don’t have a Do Digital Indian Citizen Profession Card? Haven’t created your Do Digital Indian Citizen Profession Card yet? Then you can create your Do Digital Indian Citizen Profession Card from here. It takes less than a minute.",
    do_digital_card_number:"What is your Do Digital Indian Citizen Card number?",
    your_profession:"What is your profession?",
    degree_certificate:"Degree Certificate",
    experience_letter_or_consent_form:"Experience Letter or Consent Form",
    internship_certificate:"Internship Certificate",
    reference_letter:"Reference Letter",
    salary_slip:"Salary Slip",
    training_certificate:"Training Certificate",
    basic_information1:"Your Privacy is our Priority ",
    basic_information2:"We Know that your privacy is very important and we are dedicated to keeping it safe here's how we make sure your information is protected.",
    basic_information1_1:"Only Basic Info Needed:",
    basic_information1_2:"We only ask for the information we need to help you. We won’t share your details without your permission.",
    basic_information2_1:"Safe and Secure:",
    basic_information2_2:"Your information is kept safe with strong security measures, so only you can access it.",
    basic_information3_1:"No Third-Party Sharing:",
    basic_information3_2:"We won’t share your data with anyone else. Your information is yours.",
    basic_information4_1:"You Stay In Control:",
    basic_information4_2:"You can decide what to share and can change your mind anytime.",
    basic_information5_1:"Ask Us Anytime:",
    basic_information5_2:"If you have questions about your privacy, our team is here to help!",
    your_profession1:"Enhance Your Professional Journey with our Do Digital Indian Citizen Profession Card",
    your_profession2:"Our special card is designed to help you benefit from your career,  job opportunities and skill growth as well as government schemes and services,",
    your_profession1_1:"Clear Information:",
    your_profession1_2:"We believe in being honest about how this card works. You’ll know exactly what services you can use and how to use your card. Your information will be kept safe and used only to help you.",
    your_profession2_1:"Support for Your Growth:",
    your_profession2_2:"The card gives you details about training programs and skill development opportunities. This helps you improve your skills and move forward in your career.",
    your_profession3_1:"Simple Access to Government Benefits:",
    your_profession3_2:"Your card helps you easily access important government services and benefits. We’ll guide you on how to use your card to get these benefits.",
    your_profession4_1:"Trust and Security:",
    your_profession4_2:"We know trust is important. Your personal information will be protected, and you’ll always know how it’s being used. We’re here to support you, and your data will be safe with us.",
    your_profession5_1:"Using our card is a step toward a better decision in your career, It also Provide you Your digital identity by which open doors to new opportunities and gives you exact choice for your future career  information.",
    dodigitals_login:"You can log in to your account from here.",
    dodigitals_login1:"Welcome to this exciting world. Let’s work together to make this world even more thrilling.",
    password:"Password",
    dodigitalcardnumber:"Do Digital Indian Citizen Card number",
    enhancetransparency_1:"Global Outreach of Digital India' s Vison for enhanced transparency with us",
    enhancetransparency_2:"DoDigitals India is committed to making government services more accessible and transparent for all citizens. By sharing this vision around the world, we hope to help people understand why transparency is important for good governance and how it builds trust between citizens and the government.",
    enhancetransparency_1_1:"Clarity in Communication: A Foundation of Trust",
    enhancetransparency_1_1_1_1:"Secure Verification:",
    enhancetransparency_1_1_1_2:"DoDigitals Indian citizen card provides reliable verification of user identities, significantly reducing the chances of fraud and identity theft.",
    enhancetransparency_1_1_2_1:"Real-Time Updates:",
    enhancetransparency_1_1_2_2:"Users can effortlessly update their information, guaranteeing that their profiles remain up-to- date and accurate at all times.",
    enhancetransparency_1_1_3_1:"Feedback Mechanism:",
    enhancetransparency_1_1_3_2:"Establishing platforms for all citizens to share their opinions and show that their concerns are acknowledged and acted upon.",
    enhancetransparency_1_2:"Empowering Users with Data Control:",
    enhancetransparency_1_2_1:"User Control: Users have full control over their personal information. They easily able to update, delete, or restrict access to their data, ensuring they feel secure. Also deciding what information to share and with whom, enhancing their privacy and security.",
    enhancetransparency_1_2_2:"Track Changes: Users have full permission to see how their data is used and any changes made to their profiles. This gives them control.",
    enhancetransparency_1_2_3:"Regular Checks: Our team conducts regular audits of how your data is managed and shares the findings. This ensures that users feel safe and secure in their data handling.",
    enhancetransparency_1_3:"Highlighting Digital India's Transparency Efforts Around the World",
    enhancetransparency_1_3_1_1:"Open Data Initiatives:",
    enhancetransparency_1_3_1_2:"Digital India shares government data with the public for free. this helps people see how the government works and makes it easier for them to make informed choices.",
    enhancetransparency_1_3_2_1:"Collaboration with Global Organisations:",
    enhancetransparency_1_3_2_2:"By working with international groups, Digital India learns from others and shares its own experiences. This teamwork helps improve transparency and shows India's commitment to global standards.",
    enhancetransparency_1_3_3_1:"Digital Literacy Programs:",
    enhancetransparency_1_3_3_2:"Teaching people how to use digital tools helps them understand their rights and engage with the government more effectively. This encourages more participation and demand for transparency.",
    locationwisepopulation_1:"",
    locationwisepopulation_2:"We’ve introduced an advanced and impactful feature to our project, designed to transform how you understand and analyze data through precise location-based insights. Here’s how it works:",
    locationwisepopulation_1_1_1:"Current Location Tracking:",
    locationwisepopulation_1_1_2:"Our system can detect and display the exact location of any user. This helps in providing accurate and real-time information about where the person is.",
    locationwisepopulation_1_2_1:"Detailed Area Information:",
    locationwisepopulation_1_2_2:"Not only does it show the location, but it also gives detailed information about the area. You can see how many male and female teachers or students are in that area, categorized by age.",
    locationwisepopulation_1_3_1:"Search by Pincode:",
    locationwisepopulation_1_3_2:"Users can enter any pincode and instantly get detailed information about that location, anywhere in India. This makes it easy to get insights about specific areas.",
    locationwisepopulation_1_4_1:"Useful Insights:",
    locationwisepopulation_1_4_2:"This feature is especially useful for organizations that need to understand the demographics of a particular region. It helps in making informed decisions based on the specific needs and characteristics of that location.",
    locationwisepopulation_1_5_1:"Population Insights:",
    locationwisepopulation_1_5_2:"Users can access comprehensive data on the population of any area, including the number of men, women, teachers, students, and even babies. This feature provides an easy way to understand and track population distribution across India, with real-time updates.",
    locationwisepopulation_1_6_1:"Real-Time Data:",
    locationwisepopulation_1_6_2:"The information provided is always up-to-date, ensuring that you have the most accurate data at your fingertips.",
    locationwisepopulation_1_7_2:"This location-based feature offers users an in-depth and clear understanding of any area, empowering them to plan, manage, and take action with greater precision and effectiveness.",
    already_logged_user_popup_error1:"This account is already logged in on another device.",
    already_logged_user_popup_error2:"If you are the owner of this account, please click on the logout button and log in again. For security reasons, it is recommended to change your password to keep your data more secure.",
    logout:"Logout",

  },
  hi: {
    do_digitals:"डू डिजिटल",
    female:"महिला",
    male:"पुरुष",
    student:"विद्यार्थी",
    teacher:"अध्यापक",
    location: "स्थान का चयन करें।",
    chose_location:"मेरा स्थान",
    modal_location_title:'आप किस स्थान की जानकारी लेना चाहते हैं?',
    switchLanguage: "अंग्रेजी में बदलें",
    pincode:"कृपया पिनकोड दर्ज करें",
    createcard:"भारतीय नागरिकता प्रमाण पत्र के साथ",
    citizencard:"अपनी पहचान बनाएं।",
    india_citizen_profession_card:"भारतीय नागरिक व्यवसाय कार्ड",
    herosectioncontent:"डिजिटल भारतीय नागरिक कार्ड आपकी पहचान और आपके पेशे से जुड़ी जानकारी को आसान बनाता है।",
    homepoint1:"मूल जानकारी",
    homepoint2:"आपका पेशा /कौशल/ शिक्षा",
    homepoint3:"स्थान के अनुसार जनसंख्या",
    homepoint4:"पारदर्शिता बढ़ाना",
    homepoint1_1:"यह आपकी मूल जानकारी प्रदान करता है जैसे प्रोफाइल फोटो, नाम और आईडी नंबर। हम आपकी निजी जानकारी में हस्तक्षेप नहीं करेंगे।",
    homepoint2_2:"डू डिजिटल भारतीय नागरिक कार्ड की मदद से, हर कोई अपने वर्तमान पेशे, पिछले अनुभव, शिक्षा और उपलब्धियों को सुनिश्चित कर सकता है।",
    homepoint3_3:"डू डिजिटल भारतीय नागरिक कार्ड की मदद से, हम स्थानीय क्षेत्रों के अनुसार लोगों की संख्या जान सकते हैं।",
    homepoint4_4:"इस कार्ड से हम समझ सकते हैं कि लोगों का ऑनलाइन डाटा और जानकारी सुरक्षित है और उनके डाटा के साथ क्या हो रहा है यह खुले और ईमानदारी के साथ बताया गया है",
    localareasectio_title_span:"स्थानीय क्षेत्र के लोगों के बारे में",
    localareasectio_title:"विस्तार में जानकारी",
    localareasectio_content:"यह जानकारी लोगों की संख्या से संबंधित है जिसमें छात्रों, शिक्षको, बच्चों, पुरुषों और महिलाओं को उनकी आयु समूहों के अनुसार शामिल किया गया है।",
    localareasectio_box_1:"इस कार्ड की मदद से आप अपने स्थानीय क्षेत्र में महिलाओं की संख्या जान सकेंगे।",
    localareasectio_box_2:"इस कार्ड की मदद से आप अपने स्थानीय क्षेत्र में पुरुषों की संख्या जान पाएंगे।",
    localareasectio_box_3:"इस कार्ड की मदद से आप अपने स्थानीय क्षेत्र में छात्रों की संख्या जान पाएंगे।",
    localareasectio_box_4:"इस कार्ड की मदद से आप अपने स्थानीय क्षेत्र में शिक्षकों की संख्या जान सकेंगे।",
    about_section_1:"डू डिजिटल भारतीय नागरिक कार्ड को पेश कर रहे हैं जो हर भारतीय नागरिक की संपूर्ण जानकारी और उनके व्यवसाय व काम के बारे में जानकारी प्रदान करेगा।",
    about_section_2:"इस कार्ड की मदद से, हम उम्मीदवार के राज्य, जिला और निवास के विशेष क्षेत्र को जान सकते हैं। हम आपकी गोपनीयता और निजी विवरणों को लेकर बहुत संवेदनशील हैं, इसलिए आपको इसकी चिंता करने की जरूरत नहीं है। हमें केवल आपकी मूल जानकारी चाहिए क्योंकि यह सभी सरकारी सेवाओं, लाभों और सुविधाओं का लाभ उठाने में सहायक है।",
    about_section_3:"यह कार्ड भारतीय नागरिक कार्ड का अगला कदम है। इसमें आपको भारतीय नागरिक कार्ड में दी गई सभी जानकारी मिलेगी। आपके पास अपनी पेशेवर पहचान होगी, जो नौकरी आवेदन, व्यवसाय और नेटवर्किंग के लिए आवश्यक है। इस कार्ड के माध्यम से आपको अपने पेशे की स्थिति भी मिलेगी।",
    about_section_4:"उम्मीद है कि आप अपना जीवन पहले से बेहतर पाएंगे और इन दोनों कार्डों के लाभ और सुविधाओं का आनंद लेंगे, चाहे वह आपका पेशा हो या आपका भविष्य। चलिए 'डोडिजिटल भारतीय नागरिक कार्ड' के बारे में जागरूकता और पारदर्शिता को भारत में और पूरी दुनिया में फैलाएं।",
    welcome_card:"नमस्कार, डिजिटल भारतीय नागरिक कार्ड में आपका स्वागत है।",
    welcome_card_p:"क्या डिजिटल इंडिया सिटीजन कार्ड आपको अपने इलाके की आबादी के बारे में विस्तृत जानकारी जुटाने में मदद करते हैं, जिसमें छात्रों, शिक्षकों और सभी आयु वर्ग के लोगों की संख्या शामिल है। आप अपने इलाके में कुल आबादी, पुरुषों, महिलाओं और बच्चों की संख्या और उनकी संबंधित आयु वितरण का पता लगा सकते हैं।",
    explorenow:"अधिक जानकारी",
    moreinformationofyourlocalarea:"आपके स्थानीय क्षेत्र की अधिक जानकारी",
    indiancitizencard:"भारतीय नागरिक कार्ड",
    indiancitizenprofessioncard:"भारतीय नागरिक व्यवसाय कार्ड",
    ad_box_p1:"यह कार्ड किसी भी व्यक्ति को कार्डधारक के राज्य, जिले और निवास के विशिष्ट क्षेत्र को शीघ्रता से पहचानने की सुविधा देता है",
    ad_box_p2:"भारत नागरिक कार्ड की विशेषताओं के आधार पर, यह कार्ड कार्डधारक के बारे में विस्तृत व्यावसायिक जानकारी भी प्रदान करता है।",
    footer_help_box:"यह कार्ड किसी को भी उम्मीदवार के राज्य, जिला और निवास के विशेष क्षेत्र को जल्दी से पहचानने में मदद करता है",
    addanyequation:"कोई भी प्रश्न पुचे",
    login_heading:"क्या आप एक",
    login_heading_span:"भारतीय हैं ?",
    login_user:"प्रवेश करें",
    enter_pincode:"आप किस स्थानीय क्षेत्र की जानकारी जानना चाहते हैं ? उस स्थानीय क्षेत्र का पिनकोड (6 अंक) दर्ज करें जहाँ पिनकोड पहले से उपलब्ध है।",
    people_information1:"आपने जो पिनकोड दर्ज किया है, उस स्थानीय क्षेत्र में कुल",
    people_information2:"की संख्या है.",
    females:"महिला",
    males:"पुरुष",
    students:"विद्यार्थी",
    teachers:"अध्यापक",
    card_page_top_heading1:"आपके",
    card_page_top_heading2:"स्थानीय क्षेत्र",
    card_page_top_heading3:"की जनसंख्या और",
    card_page_top_heading4:"भारत की कुल जनसंख्या",
    card_page_top_heading5:"की जानकारी !",
    card_page_top_description:"आप अपने क्षेत्र की कुल जनसंख्या के साथ ही भारत की कुल जनसंख्या में पुरुषों, महिलाओं, और बच्चों की संख्या, और उनकी उम्र के अनुसार जनसंख्या के बारे में भी जान सकते हैं।",
    total_people_information1:"भारत की कुल जनसंख्या में",
    total_people_information2:"की जनसंख्या",
    information_of:"आपके",
    your_local_area:"आपके स्थानीय क्षेत्र",
    population:"की कुल जनसंख्या",
    india:"भारत",
    local_area_population:"स्थानीय क्षेत्र की कुल जनसंख्या",
    local_area_population1:"स्थानीय क्षेत्र की कुल जनसंख्या में",
    india_population:"भारत की कुल जनसंख्या",
    populations:"कुल जनसंख्या",
    totals:"सभी",
    All:"सभी",
    males_females:"सभी",
    total_populations:"की कुल जनसंख्या",
    your_local_area1:"आपके स्थानीय क्षेत्र",
    your_local_area2:"की कुल जनसंख्या",
    india_population1:"भारत की कुल जनसंख्या की जानकारी",
    total_populations_1:"' ",
    total_populations_2:"की कुल जनसंख्या '",
    profile_image:"प्रोफ़ाइल तस्वीर",
    first_name:"पहला नाम",
    last_name:"अंतिम नाम",
    username:"खाता नाम",
    email:"ईमेल",
    mobile_number:"मोबाइल नंबर",
    gender:"लिंग",
    date_of_birth:"जन्म तिथि",
    permanent_address:"स्थायी पता",
    state:"राज्य",
    district:"जिला",
    rto_number:"आरटीओ नंबर",
    zip:"डाक कोड",
    caste_certificate:"जाति प्रमाण पत्र",
    original_residence:"मूल निवास",
    pan_card:"पैन कार्ड",
    aadhar_card:"आधार कार्ड",
    latitude:"अक्षांश",
    longitude:"देशांतर",
    enter_your:"अपना",
    enter_your1:"दर्ज करें",
    enter_your2:"अपनी",
    please_select1:"कृपया",
    please_select2:"चयन करें",
    other:"अन्य",
    submit:"जमा करें",
    creactdodigitalcard:"क्या आपके पास डू डिजिटल भारतीय नागरिक कार्ड नहीं है? क्या आपने अभी तक डू डिजिटल भारतीय नागरिक कार्ड नहीं बनाया है? तो आप यहाँ से अपना डू डिजिटल भारतीय नागरिक कार्ड बना सकते हैं। इसमें एक मिनट से भी कम समय लगेगा।",
    creactdodigitalcard_page_description:"यह कार्ड यह जानने में मदद करता है कि आप कहाँ रहते हैं, जिसमें आपका राज्य, जिला और क्षेत्र शामिल है। यह एक पहचान पत्र है,  जो आपको सरकारी सेवाओं और लाभों तक आसानी से पहुँचने में मदद करता है और राष्ट्रीय एकता को बढ़ावा देता है जिससे काम बेहतर होता है। यह कार्ड यह जानना आसान बनाता है कि आप भारत में ठीक कहाँ हैं",
    india_citizen_profession_card_description:"इस कार्ड में आपको वह सारी जानकारी और सुविधाएँ मिलेंगी, जो भारतीय नागरिक कार्ड में होती हैं। इसके अलावा, आपको एक पेशेवर पहचान पत्र भी मिलेगा। इस पेशेवर कार्ड से आप सरकार के सामने अपनी पहचान दिखा सकेंगे। इससे आपको सरकार से फायदे मिलेंगे और निजी नौकरी पाना भी आसान होगा, जिससे आपका करियर हमेशा सक्रिय रहेगा।",
    indiacitizenprofessioncard:"क्या आपके पास डू डिजिटल भारतीय नागरिक व्यवसाय कार्ड नहीं है? क्या आपने अभी तक डू डिजिटल भारतीय नागरिक व्यवसाय कार्ड नहीं बनाया है? तो आप यहाँ से अपना डू डिजिटल भारतीय नागरिक व्यवसाय कार्ड बना सकते हैं। इसमें एक मिनट से भी कम समय लगेगा।",
    do_digital_card_number:"आपका का डू डिजिटल भारतीय नागरिक कार्ड नंबर",
    your_profession:"आपका पेशा क्या है?",
    degree_certificate:"डिग्री प्रमाण पत्र",
    experience_letter_or_consent_form:"अनुभव पत्र या सहमति फॉर्म",
    internship_certificate:"इंटर्नशिप प्रमाण पत्र",
    reference_letter:"संदर्भ पत्र",
    salary_slip:"वेतन स्लिप",
    training_certificate:"वेतन स्लिप",
    basic_information1:"आपकी व्यक्तिगत जानकारी की सुरक्षा हमारी प्राथमिकता है।",
    basic_information2:" हम समझते हैं कि आपकी गोपनीयता बहुत जरूरी है और हम इसे सुरक्षित रखने के लिए पूरी तरह से कोशिश कर रहे हैं यहां हम आपकी जानकारी को सुरक्षित रखने के लिए क्या करते है।",
    basic_information1_1:"सिर्फ ज़रूरी जानकारी:",
    basic_information1_2:"हम केवल वही जानकारी मांगते हैं जो हमें आपकी मदद करने के लिए चाहिए। आपकी जानकारी बिना आपकी अनुमति के शेयर नहीं की जाएगी।",
    basic_information2_1:"सुरक्षित और सुरक्षित:",
    basic_information2_2:"आपकी जानकारी को मजबूत सुरक्षा उपायों के साथ रखा जाता है, ताकि केवल आप ही इसे देख सकें।",
    basic_information3_1:"किसी और के साथ साझा नहीं:",
    basic_information3_2:"हम आपकी डेटा किसी और के साथ नहीं साझा करेंगे। आपकी जानकारी केवल आपकी है।",
    basic_information4_1:"आपके हाथ में नियंत्रण:",
    basic_information4_2:"आप तय कर सकते हैं कि क्या साझा करना है और कभी भी अपनी राय बदल सकते हैं।",
    basic_information5_1:"किसी भी समय पूछें:",
    basic_information5_2:"यदि आपको अपनी प्राइवेसी के बारे में कोई सवाल है, तो हमारी टीम मदद के लिए हमेशा तैयार  है!",
    your_profession1:"हमारे डिजिटल भारतीय नागरिक व्यवसाय कार्ड के साथ अपने पेशेवर सफर को बेहतर बनाएं",
    your_profession2:"हमारा खास कार्ड आपके करियर, नौकरी के मौके, स्किल विकास और सरकारी योजनाओं और सेवाओं में फायदा पहुंचाने के लिए बनाया गया है",
    your_profession1_1:"साफ-सुथरी जानकारी:",
    your_profession1_2:"हम इस कार्ड के बारे में पूरी ईमानदारी से बताते हैं। आप जान पाएंगे कि कौन-कौन सी सेवाएं आप इस कार्ड से ले सकते हैं और इसे कैसे इस्तेमाल करना है। आपकी जानकारी सुरक्षित रहेगी और सिर्फ आपकी मदद के लिए ही इस्तेमाल की जाएगी।",
    your_profession2_1:"आपकी तरक्की में मदद:",
    your_profession2_2:"यह कार्ड आपको ट्रेनिंग प्रोग्राम्स और स्किल डेवलपमेंट के मौके की जानकारी देता है। इससे आप अपनी स्किल्स सुधार सकते हैं और करियर में आगे बढ़ सकते हैं।",
    your_profession3_1:"सरकारी लाभ आसानी से पाएं:",
    your_profession3_2:"आपका कार्ड आपको महत्वपूर्ण सरकारी सेवाओं और लाभों तक पहुंचने में मदद करता है। हम आपको बताएंगे कि इन लाभों को पाने के लिए अपने कार्ड का कैसे उपयोग करें।",
    your_profession4_1:"भरोसा और सुरक्षा:",
    your_profession4_2:"हम जानते हैं कि भरोसा जरूरी है। आपकी निजी जानकारी सुरक्षित रहेगी, और आपको हमेशा पता रहेगा कि इसे कैसे इस्तेमाल किया जा रहा है। हम यहां आपकी मदद के लिए हैं, और आपकी जानकारी हमारे साथ सुरक्षित रहेगी।",
    your_profession5_1:"हमारा कार्ड इस्तेमाल करना आपके करियर में बेहतर फैसले की ओर एक कदम है। यह आपको आपकी डिजिटल पहचान भी देता है, जिससे नए मौकों के दरवाजे खुलते हैं और आपको अपने भविष्य के करियर के लिए सही जानकारी मिलती है।",
    dodigitals_login:"आप अपने खाते में यहाँ से प्रवेश कर सकते हैं।",
    dodigitals_login1:"इस रोमांचक भरी दुनिया में आपका स्वागत है। चलो, हम साथ मिलकर इस दुनिया को और ज़्यादा रोमांचक बनाएं।",
    password:"पासवर्ड",
    dodigitalcardnumber:"डू डिजिटल भारतीय नागरिक कार्ड नंबर",
    enhancetransparency_1:"हमारे साथ डिजिटल इंडिया की पारदर्शिता के लिए वैश्विक पहुंच ",
    enhancetransparency_2:"डू डिजिटल इंडिया सभी नागरिकों के लिए सरकारी सेवाओं को अधिक सुलभ और पारदर्शी बनाने के लिए प्रतिबद्ध है। इस दृष्टिकोण को दुनिया भर में साझा करके, हम आशा करते हैं कि लोग समझे कि पारदर्शिता अच्छे शासन के लिए क्यों महत्वपूर्ण है और यह नागरिकों और सरकार के बीच विश्वास कैसे बनाती है।",
    enhancetransparency_1_1:"संचार में स्पष्टता: विश्वास की नींव",
    enhancetransparency_1_1_1_1:"सुरक्षित सत्यापन:",
    enhancetransparency_1_1_1_2:"डू डिजिटल भारतीय नागरिक कार्ड उपयोगकर्ता की पहचान का भरोसेमंद सत्यापन करता है, जिससे धोखाधड़ी और पहचान की चोरी का खतरा कम हो जाता है।",
    enhancetransparency_1_1_2_1:"तत्काल अपडेट:",
    enhancetransparency_1_1_2_2:"उपयोगकर्ता आसानी से अपनी जानकारी को अपडेट कर सकते हैं जिससे उनकी प्रोफाइल हमेशा वर्तमान और सही बनी रहती है।",
    enhancetransparency_1_1_3_1:"फीडबैक तंत्र:",
    enhancetransparency_1_1_3_2:"नागरिकों के विचार साझा करने के लिए प्लेटफार्म बनाना यह सुनिश्चित करता है कि उनकी चिंताओं को सुना और हल किया जा रहा है।",
    enhancetransparency_1_2:"उपयोगकर्ताओं को डेटा नियंत्रण में सशक्त बनाना:",
    enhancetransparency_1_2_1:"उपयोगकर्ता नियंत्रण: उपयोगकर्ताओं को अपनी व्यक्तिगत जानकारी पर पूरा नियंत्रण होता है। वे अपनी जानकारी को आसानी से अपडेट, डिलीट या अपने डेटा तक पहुंच को सीमित कर सकते हैं, जिससे उन्हें सुरक्षा का एहसास होता है। वे यह भी तय करते हैं कि कौन सी जानकारी साझा करनी है और किसके साथ, जिससे उनकी गोपनीयता और सुरक्षा बढ़ती है।",
    enhancetransparency_1_2_2:"बदलाव पर नज़र रखना: उपयोगकर्ताओं को यह देखने की पूरी अनुमति होती है कि उनका डेटा कैसे उपयोग किया जा रहा है और उनके प्रोफ़ाइल में कौन से बदलाव किए गए हैं। इससे उन्हें नियंत्रण मिलता है।",
    enhancetransparency_1_2_3:"नियमित जांच: हमारी टीम नियमित रूप से आपके डेटा के प्रबंधन की ऑडिट करती है और नतीजों को साझा करती है। इससे यह सुनिश्चित होता है कि उपयोगकर्ता अपने डेटा प्रबंधन में सुरक्षित महसूस करें।",
    enhancetransparency_1_3:"दुनिया में डिजिटल इंडिया के पारदर्शिता प्रयासों को उजागर करना।",
    enhancetransparency_1_3_1_1:"खुला डेटा पहल:",
    enhancetransparency_1_3_1_2:"डिजिटल इंडिया सरकारी डेटा को जनता के साथ मुफ्त में साझा करता है। इससे लोग देख सकते हैं कि सरकार कैसे काम करती है और वे बेहतर निर्णय ले सकते हैं।",
    enhancetransparency_1_3_2_1:"वैश्विक संगठनों के साथ सहयोग:",
    enhancetransparency_1_3_2_2:"अंतरराष्ट्रीय समूहों के साथ काम करके, डिजिटल इंडिया दूसरों से सीखता है और अपने अनुभव साझा करता है। यह सहयोग पारदर्शिता में सुधार करने में मदद करता है और भारत की वैश्विक मानकों के प्रति प्रतिबद्धता को दर्शाता है।",
    enhancetransparency_1_3_3_1:"डिजिटल साक्षरता कार्यक्रम:",
    enhancetransparency_1_3_3_2:"लोगों को डिजिटल उपकरणों का उपयोग सिखाना उन्हें उनके अधिकारों को समझने और सरकार के साथ अधिक प्रभावी ढंग से जुड़ने में मदद करता है। इससे अधिक भागीदारी और पारदर्शिता की मांग बढ़ती है",
    locationwisepopulation_1:"",
    locationwisepopulation_2:"हमने अपने प्रोजेक्ट में एक नया और प्रभावशाली फीचर जोड़ा है, जो लोकेशन के आधार पर डेटा को समझने और विश्लेषण करने को बहुत आसान बना देता है। इस फीचर की खासियतें है।",
    locationwisepopulation_1_1_1:"करंट लोकेशन ट्रैकिंग:",
    locationwisepopulation_1_1_2:"हमारा सिस्टम किसी भी यूजर की सही लोकेशन को पहचानकर दिखा सकता है। इससे आपको उस व्यक्ति के बारे में सटीक और रियल-टाइम जानकारी मिलती है कि वो कहाँ है।",
    locationwisepopulation_1_2_1:"क्षेत्र की विस्तृत जानकारी:",
    locationwisepopulation_1_2_2:"लोकेशन दिखाने के साथ ही यह फीचर उस क्षेत्र के बारे में भी विस्तृत जानकारी देता है। आप देख सकते हैं कि उस क्षेत्र में कितने पुरुष और महिला शिक्षक या छात्र हैं, और वो भी उम्र के हिसाब से।",
    locationwisepopulation_1_3_1:"पिनकोड से खोजें:",
    locationwisepopulation_1_3_2:"यूजर्स किसी भी पिनकोड को डालकर उस स्थान की जानकारी देख सकते हैं, पूरे भारत में। इससे किसी विशेष क्षेत्र के बारे में जानकारी प्राप्त करना आसान हो जाता है।",
    locationwisepopulation_1_4_1:"उपयोगी जानकारी:",
    locationwisepopulation_1_4_2:"यह फीचर खासकर उन संगठनों के लिए बहुत फायदेमंद है जिन्हें किसी विशेष क्षेत्र की जनसांख्यिकी (demographics) को समझने की ज़रूरत होती है। इससे आप उस क्षेत्र की ज़रूरतों और विशेषताओं के आधार पर सही निर्णय ले सकते हैं।",
    locationwisepopulation_1_5_1:"जनसंख्या की जानकारी:",
    locationwisepopulation_1_5_2:"उपयोगकर्ता किसी भी क्षेत्र की जनसंख्या की विस्तृत जानकारी प्राप्त कर सकते हैं, जिसमें पुरुष, महिलाएँ, शिक्षक, छात्र और यहां तक कि बच्चे भी शामिल हैं। यह फीचर भारत में किसी भी क्षेत्र की जनसंख्या को समझने और ट्रैक करने का आसान तरीका प्रदान करता है, और यह जानकारी हमेशा अपडेट रहती है।",
    locationwisepopulation_1_6_1:"रियल-टाइम डेटा:",
    locationwisepopulation_1_6_2:"दी गई जानकारी हमेशा अपडेट रहती है, जिससे आपके पास सबसे सटीक डेटा उपलब्ध होता है।",
    locationwisepopulation_1_7_2:"यह लोकेशन-आधारित फीचर यूज़र्स को किसी भी क्षेत्र की गहरी और स्पष्ट समझ प्रदान करता है, जिससे वे योजना बनाने, प्रबंधन करने और कार्यवाही करने में अधिक सटीकता और प्रभावशीलता के साथ सक्षम हो जाते है।",
    already_logged_user_popup_error1:"इस खाते में पहले ही किसी और डिवाइस पर लॉगिन किया हुवा है।",
    already_logged_user_popup_error2:"यदि आप इस खाते के मालिक हैं, तो कृपया इस लॉगआउट बटन पर क्लिक करें और फिर से लॉगिन करें। सुरक्षा के लिए, यह सलाह दी जाती है कि आप अपना पासवर्ड बदल लें ताकि आपका डेटा और अधिक सुरक्षित रहे।",
    logout:"लॉगआउट",


  },
};

export default translations;



