const endpoints = {
  getStateList: 'getStateList',
  getDistrictList: 'getDistrictList',
  createUser: 'createUser',
  loginUser: '/login',
  validateToken: '/validateToken',
  logout: 'logout',
  getUserProfile: '/getUserProfile',
  getUserDetail: '/getUserDetail',
  getUserData: '/getUserData',
  getUserCount: '/getUserCount',
  createProfessionCard: '/createProfessionCard',
  switchUserLogin: '/switchUserLogin',
};

export default endpoints;
