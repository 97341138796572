import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoMdNotifications, IoMdClose } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import Imagepng from "../components/Image_png";
import Imagewebp from "../components/Image_webp";
import * as colors from "../assets/css/Colors";
import Location from "../modal/Location";
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from '../detectlanguage/translate';
import Serverimage from "../components/Serverimage";
import Logout from '../login/Logout';

const Header = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAniActive2(false);
    setIsAniActive3(false);
    setIsAniActive4(false);
    navigate('/login');
  };

  const { language, toggleLanguage } = useContext(LanguageContext);
  const [locationName, setLocationName] = useState('');
  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const [scrollClass, setScrollClass] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrollClass('dmact');
      } else {
        setScrollClass('');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".ban-short-links");
    if (element) {
      element.classList.add("ani");
    }
  }, []);

  const [isAniActive2, setIsAniActive2] = useState(false);
  const [isAniActive3, setIsAniActive3] = useState(false);
  const [isAniActive4, setIsAniActive4] = useState(false);

  const handleClick2 = () => {
    setIsAniActive2(!isAniActive2);
  };

  const handleClick3 = () => {
    setIsAniActive3(!isAniActive3);
  };

  const handleClick4 = () => {
    setIsAniActive4(!isAniActive4);
  };

  const handleCloseClick2 = (event) => {
    event.stopPropagation();
    setIsAniActive2(false);
  };

  const handleCloseClick3 = (event) => {
    event.stopPropagation();
    setIsAniActive3(false);
  };

  const handleCloseClick4 = (event) => {
    event.stopPropagation();
    setIsAniActive4(false);
  };

  return (
    <>
      <div className={`hom-top ${scrollClass}`}>
        <Container>
          <Row>
            <div className="hom-nav db-open">
              <Link to="/" className="top-log">
                <div className="desktop-view">
                  <Imagewebp text={'do-digital'} />
                </div>
                <div className="mobile-view">
                  <Imagewebp text={'do'} />
                </div>
              </Link>
              <div className="top-ser">
                <ul>
                  <li className="sr-sea">
                    <p style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                      Location: {locationName ? locationName : 'Fetching location...'}
                    </p>
                  </li>
                </ul>
              </div>
              {user ? (
                <>
                  <div style={{ display: 'contents' }}>
                    <div className="top-noti">
                      <span
                        className="material-icons db-menu-noti"
                        onClick={handleClick3}
                        style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}
                      >
                        <IoMdNotifications />
                        <i style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_white_color : colors.theme_black_color }}>00</i>
                      </span>
                      <div className='location-gif'>
                        <Location onLocationUpdate={setLocationName} />
                        <button
                          onClick={toggleLanguage}
                          style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color, margin: '0 10px', color: colorScheme === 'dark' ? colors.white : colors.white }}
                          className="btn"
                        >
                          {translate('switchLanguage', language)}
                        </button>
                      </div>
                      <div
                        className={
                          isAniActive3
                            ? "db-noti top-noti-win act"
                            : "db-noti top-noti-win"
                        }
                      >
                        <span
                          className="material-icons db-menu-clo"
                          onClick={handleCloseClick3}
                        >
                          <IoMdClose />
                        </span>
                        <h4>Notifications</h4>
                        <ul id="all-notif-ul"></ul>
                        <span
                          id="no-noti-clr-noti"
                          className="no-noti-clr-noti"
                        >
                          No Notifications to Show
                        </span>
                      </div>
                    </div>
                    <div className="al">
                      <div className="head-pro" onClick={handleClick2}>
                      <Serverimage text={user.user_profile} />
                        <span className="fclick near-pro-cta"></span>
                      </div>
                      <div className={isAniActive2 ? "db-menu act" : "db-menu"}>
                        <span
                          className="material-icons db-menu-clo"
                          onClick={handleCloseClick2}
                        >
                          <IoMdClose />
                        </span>
                        <div className="ud-lhs-s1">
                          <Serverimage text={user.user_profile} />
                          
                          <div className="ud-lhs-pro-bio">
                            <h4 style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>{user.first_name} {user.last_name}</h4>
                            <b>{user.username}</b>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <Link to=":username" className="">
                              <Imagepng text={'dbl1'} />
                              My Dashboard
                            </Link>
                          </li>
                          <Logout/>
                          {/* <li>
                            <Link onClick={handleLogout}>
                              <Imagepng text={'dbl12'} />
                              Log Out
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="mob-menu">
                      <div className="mob-me-ic" onClick={handleClick4}>
                        <i className="material-icons">
                          <IoMenu />
                        </i>
                      </div>
                      <div
                        className={
                          isAniActive4
                            ? "mob-me-all mobmenu-show"
                            : "mob-me-all"
                        }
                      >
                        <div className="mob-me-clo">
                          <i
                            className="material-icons"
                            onClick={handleCloseClick4}
                          >
                            <IoMdClose />
                          </i>
                        </div>
                        <div className="ud-lhs-s1">
                          <Serverimage text={user.user_profile} />
                          <div className="ud-lhs-pro-bio">
                            <h4 style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>{user.first_name} {user.last_name}</h4>
                            <b>{user.username}</b>
                          </div>
                        </div>
                        <div className="mv-pro-menu ud-lhs-s2">
                          <ul>
                            <li>
                              <Link to=":username" className="">
                                <Imagepng text={'dbl1'} />
                                My Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link onClick={handleLogout}>
                                <Imagepng text={'dbl12'} />
                                Log Out
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="mv-cate">
                          <h4>All Categories</h4>
                          <ul>
                            <li>
                              <Link to="">Real Estate</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="login-signup-header-menu">
                  <ul className="bl">
                    <li>
                    <Location onLocationUpdate={setLocationName} />
                    </li>
                    <li>
                    <button
                          onClick={toggleLanguage}
                          style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color, margin: '0 10px', color: colorScheme === 'dark' ? colors.white : colors.white }}
                          className="btn"
                        >
                          {translate('switchLanguage', language)}
                        </button>
                    </li>
                    <li>
                      <Link style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }} to="/Login"> {translate('login_user', language)}</Link>
                    </li>
                  </ul>
                </div>
                
              )}
            </div>
          </Row>
        </Container>
      </div>
      <span
        className={`bg ${isAniActive2 ? "act" : ""} ${isAniActive3 ? "act" : ""
          } ${isAniActive4 ? "act" : ""}`}
      >
        &nbsp;
      </span>
    </>
  );
};

export default Header;
