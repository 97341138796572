import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'; 

const AutoLogout = () => {
  const { logout, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;

    const clearAndLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      logout();
      navigate('/'); 
    };

    // Auto logout after 1 minute of inactivity (same as before)
    const startInactivityTimer = () => {
      timeoutId = setTimeout(clearAndLogout, 30 * 60 * 1000); 
    };

    const resetInactivityTimer = () => {
      clearTimeout(timeoutId);
      startInactivityTimer();
    };

    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);
    startInactivityTimer();

    // Network change detection (logout on network change)
    const handleNetworkChange = () => {
      if (!navigator.onLine) { // Check if offline
        clearAndLogout();
      } else if (isAuthenticated) { // Check if online and authenticated
        // You might want to refresh user data or perform other actions here
        // For now, just restarting the inactivity timer
        resetInactivityTimer(); 
      }
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, [logout, navigate, isAuthenticated]); 

  return null; 
};

export default AutoLogout;