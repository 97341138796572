// googleMapsAPI.js
import axios from 'axios';

const API_KEY = 'AIzaSyBX_Bgo3BVRq7FDKO9JvUiumdiyOwQBaJM'; // Replace with your Google Maps API key

const getGeocodeFromCoordinates = async (lat, lng) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
    );
    if (response.data.results.length > 0) {
      return response.data.results[0].formatted_address;
    } else {
      throw new Error('No address found for the given coordinates');
    }
  } catch (error) {
    console.error('Error fetching address from coordinates', error);
    throw error;
  }
};

const getGeocodeFromPincode = async (pincode) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=${API_KEY}`
    );
    if (response.data.results.length > 0) {
      const { lat, lng } = response.data.results[0].geometry.location;
      const address = response.data.results[0].formatted_address;
      return { lat, lng, address };
    } else {
      throw new Error('No location found for the given pincode');
    }
  } catch (error) {
    console.error('Error fetching location from pincode', error);
    throw error;
  }
};

export { getGeocodeFromCoordinates, getGeocodeFromPincode };
