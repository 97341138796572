export const detectOS = () => {
    const userAgent = window.navigator.userAgent;
  
    if (userAgent.includes('Win')) {
      return 'windows';
    } else if (userAgent.includes('Mac')) {
      return 'macos';
    } else if (userAgent.includes('Linux')) {
      return 'linux';
    } else {
      return 'unknown';
    }
  };
  