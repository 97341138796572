import axios from 'axios';
import config from './config';
import endpoints from './endpoints';

const api = axios.create({
  baseURL: config.apiBaseUrl,
});

const handleApiError = (error) => {
  console.error('API call error:', error);
  // Optionally handle errors globally (e.g., show error message to the user)
  throw error; // Re-throw the error for more specific handling in components
};

export const getStateList = async () => {
  try {
    const response = await api.get(endpoints.getStateList);
    return response.data;
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

export const getDistrictList = async (stateId) => {
  try {
    const response = await api.post(endpoints.getDistrictList, { state_id: stateId });
    return response.data;
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post(endpoints.createUser, userData);
    return response.data;
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

export const loginUser = async (loginData) => {
  try {
    const response = await api.post(endpoints.loginUser, loginData);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const fetchProfile = async (token) => {
  try {
    const response = await api.get('/getUserProfile/{id}', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

export const fetchUserProfileByUsername = async (username) => {
  try {
    const response = await api.get(`/getUserProfile/${username}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile by username:', error);
    throw error;
  }
};

export const fetchUserDetailsById = async (id) => {
  try {
    const response = await api.get(`/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user details by ID:', error);
    throw error;
  }
};
export const getUserDetail = async (id) => {
  try {
    const response = await api.get(`/getUserDetail/${id}`);
    return response.data.data;
  } catch (error) {
    console.error('API call error:', error.response); // Check for response
    if (error.response && error.response.status === 404) {
      throw new Error('User not found'); 
    } else {
      throw new Error('API request failed');
    }
  }
};

export const getUserData = (token) => {
  return api.get(endpoints.getUserData, {
    headers: { Authorization: `Bearer ${token}` } // If your API requires authorization
  })
  .then(response => response.data)
  .catch(error => {
    console.error('Error fetching user data:', error);
    throw error; // You can handle the error differently if needed
  });
};

export const validateToken = (token) =>
  api.post(endpoints.validateToken, {}, {
    headers: { Authorization: `Bearer ${token}` },
  }).then(response => response.data).catch(handleApiError);

  export const logoutUser = (token) =>
    api.get(endpoints.logout, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => response.data).catch(handleApiError);
    


    export const getUserCount = async (zipcode = null) => {
      try {
        const response = await api.post(endpoints.getUserCount, { zipcode });
        return response.data;
      } catch (error) {
        console.error('Error fetching user count:', error);
        throw error;
      }
    };

    // export const createProfessionCard = async (formData) => {
    //   try {
    //     const response = await api.post(endpoints.createProfessionCard, formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     });
    //     return response.data;
    //   } catch (error) {
    //     console.error('Error creating profession card:', error.response ? error.response.data : error.message);
    //     if (error.response) {
    //       throw error.response.data;
    //     } else {
    //       throw new Error('API request failed');
    //     }
    //   }
    // };


    export const createProfessionCard = async (formData) => {
      try {
        const response = await api.post(endpoints.createProfessionCard, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data;
      } catch (error) {
        console.error('Error creating profession card:', error.response ? error.response.data : error.message);
        
        let errorMessage;
        if (error.response && error.response.data) {
          // If error is an object with nested messages
          if (typeof error.response.data.error === 'object') {
            errorMessage = Object.values(error.response.data.error).flat().join(', ');
          } else {
            errorMessage = error.response.data.error || 'An unexpected error occurred.';
          }
        } else {
          errorMessage = error.message || 'An unexpected error occurred.';
        }
        
        throw new Error(errorMessage);
      }
    };

// Switch user login API function
export const switchUserLogin = async (userId, ipAddress) => {
  try {
    // Log the data being sent for debugging purposes
    console.log('Switching user login with data:', { userId, ipAddress });

    const response = await api.post(endpoints.switchUserLogin, {
      user_id: userId,
      ip_address: ipAddress,
    });

    // Log response for debugging purposes
    console.log('API Response:', response.data);

    return response.data;
  } catch (error) {
    // Log full error for debugging
    console.error('Error switching user login:', error.response ? error.response.data : error.message);

    // Optionally, handle specific error cases
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request
    } else if (error.response && error.response.status === 404) {
      // Handle 404 Not Found
    } else {
      // Handle other errors
    }

    throw error;
  }
};

