import React, { useEffect, useState, useContext, useRef } from 'react';
import Header from '../layout/Header';
import * as colors from "../assets/css/Colors";
import './adminstyle.css';

// profile import
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Loader from '../components/Loader';
import { LanguageContext } from '../detectlanguage/LanguageContext';
import { fetchProfile, fetchUserDetailsById } from '../components/Api';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { AuthContext } from '../login/AuthContext';
import whiteimg from '../assets/img/curve1.png';
import Darkbg from '../assets/img/user-login-dark.webp';
import whitebg from '../assets/img/user-login-light.webp';
import Darkimg from '../assets/img/curve2.png';
import Serverimage from '../components/Serverimage';
import Userprofileimage from '../components/Userprofileimage';
import { VscDashboard } from "react-icons/vsc";

// qrcode
import logo from "./logo-svg.svg";
import QRCodeStyling from "qr-code-styling";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// Darkimg and whiteimg card
import darkdesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Desktop-1.webp';
import darkdesktopimg2 from '../assets/img/do-digital-card/Do-DIgital-Orange-Desktop-2.webp';
import darkmobileimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Mobile-1.webp';
import darkmobileimg2 from '../assets/img/do-digital-card/Do-Digital-Orange-Mobile-2.webp';
import whitedesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Desktop-1.webp';
import whitedesktopimg2 from '../assets/img/do-digital-card/Do-Digital-Green-Desktop-2.webp';
import whitemobileimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-1.webp';
import whitemobileimg2 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-2.webp';
import Darkcardimg from '../assets/img/server/do-gif-orange.gif';
import whitecardimg from '../assets/img/server/do-gif-green.gif';


// QR code configuration
const secretKey = CryptoJS.enc.Utf8.parse('1234567890123456'); // Convert to WordArray

const encryptUserId = (userId) => {
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a new IV for each encryption
  const encrypted = CryptoJS.AES.encrypt(userId.toString(), secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return {
    encryptedId: encrypted.toString(),
    iv: iv.toString(CryptoJS.enc.Base64)
  };
};

const generateEncodedId = (encryptedId, iv) => {
  const encodedId = encodeURIComponent(encryptedId);
  const encodedIv = encodeURIComponent(iv);
  return `${encodedId}?iv=${encodedIv}`;
};

const Dashboard = () => {

  const [qrCodeURL, setQrCodeURL] = useState(null);



  const [userId, setUserId] = useState(null);
  const [encodedId, setEncodedId] = useState('');




  useEffect(() => {
    if (encodedId) {
      // Directly set QR code URL using encodedId
      const newQrCodeURL = `https://dodigitals.in/Verifications/${encodedId}`;
      console.log(newQrCodeURL);
      setQrCodeURL(newQrCodeURL);
    }
  }, [encodedId]);
  // download functio in card start


  const [fileExt, setFileExt] = useState("png");
  const divRef1 = useRef(null);  // Ref for first download content
  const divRef2 = useRef(null);  // Ref for second download content
  
  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };
  
  const onDownloadClick = (divRef) => {
    html2canvas(divRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
  
      if (fileExt === "pdf") {
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
  
        const canvasWidthInMm = (canvas.width * 0.07);
        const canvasHeightInMm = (canvas.height * 0.07);
  
        let yOffset = 0;
        let remainingHeight = canvasHeightInMm;
  
        // Padding from the top on the first page
        const initialYOffset = 0; // Change this value for more or less padding
  
        while (remainingHeight > 0) {
          const pageHeightToUse = Math.min(remainingHeight, pageHeight);
          
          // Center the image horizontally
          const xOffset = (pageWidth - canvasWidthInMm) / 2;
  
          pdf.addImage(
            imgData,
            'PNG',
            xOffset,
            -yOffset + (yOffset === 0 ? initialYOffset : 0), // Apply initial Y offset only on the first page
            canvasWidthInMm,
            canvasHeightInMm
          );
  
          remainingHeight -= pageHeight;
          yOffset += pageHeight;
  
          if (remainingHeight > 0) {
            pdf.addPage();
          }
        }
  
        pdf.save("content.pdf");
      } else {
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `content.${fileExt}`;
        link.click();
      }
    });
  };
  
  
  
  
  
  



  // download function in card end

  const createQRCode = (data) => new QRCodeStyling({
    width: 500,
    height: 500,
    data: data,
    margin: 0,
    qrOptions: { typeNumber: 0, mode: "Byte", errorCorrectionLevel: "L" },
    imageOptions: { hideBackgroundDots: true, imageSize: 1, margin: 0 },
    dotsOptions: {
      type: "rounded", color: "#ff14a5", gradient: {
        type: "radial", rotation: 0, colorStops: [
          { offset: 0, color: colorScheme === 'dark' ? colors.theme_white_color : colors.theme_black_color },
          { offset: 1, color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color },
        ],
      }
    },
    backgroundOptions: { color: "#ffffff" },
    image: logo,
    cornersSquareOptions: {
      type: "extra-rounded", color: "#000000", gradient: {
        type: "linear", rotation: 1.5707963267948966, colorStops: [
          { offset: 0, color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color },
          { offset: 1, color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color },
        ],
      }
    },
    cornersDotOptions: { type: "extra-rounded", color: colorScheme === 'dark' ? colors.theme_white_color : colors.theme_black_color },
  });




  const darkdesktopimg1URL = darkdesktopimg1;
  const darkdesktopimg2URL = darkdesktopimg2;
  const darkmobileimg1URL = darkmobileimg1;
  const darkmobileimg2URL = darkmobileimg2;
  const whitedesktopimg1URL = whitedesktopimg1;
  const whitedesktopimg2URL = whitedesktopimg2;
  const whitemobileimg1URL = whitemobileimg1;
  const whitemobileimg2URL = whitemobileimg2;
  const lightcardImageURL = whitecardimg;
  const darkcardImageURL = Darkcardimg;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const { username } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const { logout } = useContext(AuthContext) || {};
  const [loading, setLoading] = useState(false);

  const lightImageURL = whiteimg;
  const darkImageURL = Darkimg;
  const lightBgURL = whitebg;
  const darkBgURL = Darkbg;
  const { language } = useContext(LanguageContext);

  const secretKey = '1234567890123456';

  const decryptId = (encryptedId) => {
    try {
      const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (e) {
      console.error('Error decrypting ID:', e);
      setError('Error decrypting ID');
      return null;
    }
  };

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const queryParams = new URLSearchParams(location.search);
    const encryptedIdFromQR = queryParams.get('id');

    const fetchData = async () => {
      try {
        setLoading(true);
        let data;

        if (token) {
          const profileResponse = await fetchProfile(token, username);
          if (!profileResponse || !profileResponse.data || !profileResponse.data.data) {
            if (logout) {
              logout();
            }
            throw new Error('Profile data not found or token invalid');
          }
          data = profileResponse.data.data;
        } else if (encryptedIdFromQR) {
          const decryptedId = decryptId(encryptedIdFromQR);
          const userDetailsResponse = await fetchUserDetailsById(decryptedId);
          if (!userDetailsResponse || !userDetailsResponse.data) {
            throw new Error('Profile data not found for QR code');
          }
          data = userDetailsResponse.data;
          console.log("Decrypted User ID:", decryptedId);
        } else {
          alert('Please log in or use a valid QR code to view the profile.');
          navigate('/', { replace: true });
          return;
        }

        if (data && data.username) {
          if (data.username !== username) {
            navigate(`/${data.username}`, { replace: true });
          } else {
            setProfileData(data);
          }
        } else {
          setError('Profile data not found or invalid format');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username, navigate, location, logout, location.search]);

  useEffect(() => {
    if (profileData && profileData.id) {
      const userId = profileData.id.toString(); // Ensure userId is a string
      const { encryptedId, iv } = encryptUserId(userId);
      const encoded = generateEncodedId(encryptedId, iv);
      setEncodedId(encoded);
    }
  }, [profileData]);

  const profileId = profileData ? profileData.id : null;


  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);
  const boxstyle = {
    backgroundColor: colorScheme === 'dark' ? colors.theme_black_color_bg : colors.theme_white_color_bg,
  };
  const boxstyle1 = {
    backgroundColor: colorScheme === 'dark' ? colors.theme_black_color_bg : colors.theme_white_color_bg,
    border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
  };
  const boxstyle2 = {
    backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color,
    border: `1px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
  };
  const boxstyleqrcode = {
    border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
    borderRadius: '8px',
  };
  //  gradient color Start
  const styles = {
    background: colorScheme === 'dark'
      ? `linear-gradient(to bottom, ${colors.theme_black_color}, ${colors.theme_white_color} 100%)`
      : `linear-gradient(to bottom, ${colors.theme_white_color}, ${colors.theme_black_color} 100%)`,
  };
  const styles_select_input = {
    border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
    borderRadius: '8px',
  };






  // gradient color End

  useEffect(() => {
    if (qrCodeURL && profileData) {
      const qrContainer1 = document.getElementById('qrContainer1');
      const qrContainer2 = document.getElementById('qrContainer2');
      const qrContainer3 = document.getElementById('qrContainer3');
      const qrContainer4 = document.getElementById('qrContainer4');

      const qrCode1 = createQRCode(`${qrCodeURL}`);
      const qrCode2 = createQRCode(`${qrCodeURL}`);
      const qrCode3 = createQRCode(`${qrCodeURL}`);
      const qrCode4 = createQRCode(`${qrCodeURL}`);

      if (qrContainer1) {
        qrCode1.append(qrContainer1);
      }

      if (qrContainer2) {
        qrCode2.append(qrContainer2);
      }
      if (qrContainer3) {
        qrCode3.append(qrContainer3);
      }

      if (qrContainer4) {
        qrCode4.append(qrContainer4);
      }
    }
  }, [qrCodeURL, profileData]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!profileData) {
    return null;
  }
  const firstLetter = profileData.fname.charAt(0);
  return (
    <>
      {loading && <Loader />}
      <Header />
      <div>

        {profileData ? (
          <section className="ud" style={{ marginTop: '66px', borderTop: '2px solid', borderColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
            <div className="ud-inn" style={boxstyle}>
              <div className="ud-lhs">
                <div className="ud-lhs-s1" style={{ borderRadius: '0 0px 100px 100px', background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color, }}>
                  {/* <Serverimage text={profileData.user_profile} /> */}
                  <div className="ud-lhs-pro-bio">
                    <h4 style={{ color: colorScheme === 'dark' ? colors.white : colors.white }}>{profileData.fname}{profileData.lname}</h4>
                    <b style={{ color: colorScheme === 'dark' ? colors.white : colors.white }}>{profileData.username}</b>
                  </div>
                </div>
                <div className="ud-lhs-s2">
                  <ul>
                    <li className='left-menu-admin-style'>
                      <a href="dashboard" className="db-lact" style={boxstyle2}>
                        <VscDashboard /> My
                        Dashboard</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ud-main">
                <div className="ud-main-inn">
                  <div className="ud-cen">
                    <div className="cd-cen-intr">
                      <div className="cd-cen-intr-inn" style={styles}>
                        <h2>Welcom back, <b>{profileData.fname} {profileData.lname}</b></h2>
                        <p>Stay up to date reports in your listing, products, events and blog reports here</p>
                      </div>
                    </div>

                    <Tab.Container defaultActiveKey="profile_data">

                      <Nav className="ud-cen-s1">
                        <Nav.Item>
                          <Nav.Link eventKey="profile_data">
                            <div style={boxstyle1}>
                              <b style={boxstyle2}>{firstLetter}</b>
                              <h4>Your Profile</h4>
                              <p>All Main Data</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        {profileData.profession_card && profileData.profession_card.role_id && (
                          <Nav.Item>
                            <Nav.Link eventKey="profession_card">
                              <div style={boxstyle1}>
                               
                                {profileData.profession_card.role_id === 2 &&  <b style={boxstyle2}> S </b>}
                                {profileData.profession_card.role_id === 1 &&  <b style={boxstyle2}> T </b>}

                                <h4>Profession Card Data</h4>
                                <p>Your All Profession Card Data</p>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        )}

                        <Nav.Item>
                          <Nav.Link eventKey="old_data">
                            <div style={boxstyle1}>
                              <b style={boxstyle2}>O</b>
                              <h4>Old Update Data</h4>
                              <p>Your All Old Data</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className='admin-page-main-content-style'>
                        <Tab.Pane eventKey="profile_data">
                          <div className="ud-cen-s3 ud-cen-s4">
                            <h2>Profile page</h2>
                            <a href="db-my-profile-edit" className="db-tit-btn" style={boxstyle2}>Edit profile page</a>
                            <div className="ud-payment ud-pro-link">
                              <div className="pay-lhs">
                                <div className="lis-pro-badg" style={boxstyle}>
                                  <div>
                                    <Serverimage text={profileData.user_profile} />
                                    <h4>{profileData.fname}{profileData.lname}</h4>
                                    <p>{formatDate(profileData.dob)}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="pay-rhs">
                                <ul>
                                  <li><b>Username : </b> {profileData.username} </li>
                                  <li><b>Address: </b> <span>{profileData.paddress}</span></li>
                                  <li><b>Mobile Number :</b> <span>{profileData.mobilenumber}</span></li>
                                  <li><b>Email : </b> <span>{profileData.email}</span></li>
                                  <li><b>DOB : </b> <span>{formatDate(profileData.dob)}</span></li>
                                  <li><b>DoDigitals Id : </b> <span>{profileData.dodigi_id}</span></li>
                                  <li className="pro">
                                    <input
                                      type="text"
                                      value={`https://dodigitals.in/Verifications/${encodedId}`}
                                      readOnly
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* card start */}
                          <div className="ud-cen-s3 ud-cen-s4">

                            <div className="user-admin-page">
                            <div className='profile-card-download'>
  <h2>Card Download</h2>
  <select onChange={onExtensionChange} value={fileExt} style={styles_select_input}>
    <option value="png">PNG</option>
    <option value="jpeg">JPEG</option>
    <option value="webp">WEBP</option>
    <option value="pdf">PDF</option>
  </select>
  <button onClick={() => onDownloadClick(divRef1)} style={boxstyle2}>Download</button>
</div>
                              <div ref={divRef1} className='user-card-style-in-admin-page'>
                                <div className="ud-cen-s3 ud-cen-s4">
                                  <div className="ud-payment ud-pro-link card-dassebord-user">
                                    <div className="popup-content-front">
                                      <div className="do-digital-back-img">
                                        <img src={colorScheme === 'light' ? whitedesktopimg1URL : darkdesktopimg1URL} alt="sharp" />
                                      </div>
                                      <Row className="do-digital-card-data">
                                        <Col sm={5}>
                                          <div className="do-digital-card-data-user-profile">
                                            <Userprofileimage text={profileData.user_profile} />
                                          </div>
                                        </Col>
                                        <Col sm={7}>
                                          <div className="do-digital-card-data-user-name">
                                            <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                              <span className='user-name-in-profile'>{profileData.fname} {profileData.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                                            </p>
                                          </div>
                                          <div className="do-digital-card-data-user-id-number">
                                            <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                              <strong>ID NO.: {profileData.dodigi_id}</strong>
                                            </p>
                                          </div>
                                          <div className="do-digital-card-data-site">
                                            <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                              https://dodigitals.in
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                                <div className="ud-cen-s3 ud-cen-s4">
                                  <div className="ud-payment ud-pro-link card-dassebord-user">
                                    <div className="popup-content-horizontal-back">
                                      <div className="do-digital-back-img">
                                        <img src={colorScheme === 'light' ? whitedesktopimg2URL : darkdesktopimg2URL} alt="sharp" />
                                      </div>
                                      <div className="do-digital-back-side-qr">
                                        <div id="qrContainer1" style={boxstyleqrcode} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="ud-cen-s3 ud-cen-s4">
                                  <div className="ud-payment ud-pro-link card-dassebord-user">
                                    <div className="do-digital-frient" id="popup-content-vertical-front">
                                      <div className="do-digital-back-img">
                                        <img src={colorScheme === 'light' ? whitemobileimg1URL : darkmobileimg1URL} alt="sharp" />
                                      </div>
                                      <div className="do-digital-card-data">
                                        <div className="do-digital-card-data-user-profile">
                                          <Userprofileimage text={profileData.user_profile} />
                                        </div>
                                        <div className="do-digital-card-data-user-name">
                                          <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                            <span className='user-name-in-profile'>{profileData.fname} {profileData.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                                          </p>
                                        </div>
                                        <div className="do-digital-card-data-user-id-number">
                                          <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                            <strong>ID NO.: {profileData.dodigi_id}</strong>
                                          </p>
                                        </div>
                                        <div className="do-digital-card-data-site">
                                          <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                            https://dodigitals.in
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="ud-cen-s3 ud-cen-s4">
                                  <div className="ud-payment ud-pro-link card-dassebord-user">
                                    <div className="do-digital-frient" id="popup-content-vertical-back">
                                      <div className="do-digital-back-img">
                                        <img src={colorScheme === 'light' ? whitemobileimg2URL : darkmobileimg2URL} alt="sharp" />
                                        <div className="do-digital-back-side-qr">
                                          <div id="qrContainer2" style={boxstyleqrcode} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* card end */}

                          <div className="ud-cen-s3 ud-cen-s4">
                            <h2>Caste Certificate</h2>
                            <div className="ud-payment ud-pro-link">
                              <Serverimage text={profileData.caste_cert} />
                            </div>
                          </div>
                          <div className="ud-cen-s3 ud-cen-s4">
                            <h2>Original Residence</h2>
                            <div className="ud-payment ud-pro-link">
                              <Serverimage text={profileData.original_residence} />
                            </div>
                          </div>
                        </Tab.Pane>
                        {profileData.profession_card && profileData.profession_card.role_id && (
                          <Tab.Pane eventKey="profession_card">
                            {/* {profileData.profession_card.role_id === 1 && (
                              <div className="role_1">Content for Role 1</div>
                            )} */}
                            {/* {profileData.profession_card.role_id === 2 && (
                              <div className="role_2">Content for Role 2</div>
                            )} */}
                            <div className="ud-cen-s3 ud-cen-s4">
                              <h2>Profession Profile page</h2>
                              <a href="db-my-profile-edit" className="db-tit-btn" style={boxstyle2}>Edit profile page</a>
                              <div className="ud-payment ud-pro-link">
                                <div className="pay-lhs">
                                  <div className="lis-pro-badg" style={boxstyle}>
                                    <div>
                                      <Serverimage text={profileData.user_profile} />
                                      <h4>{profileData.fname}{profileData.lname}</h4>
                                      <p>{formatDate(profileData.dob)}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="pay-rhs">
                                  <ul>
                                    {profileData.profession_card.created_at && (
                                      <li><b>Created Profession Card Date: </b> <span> {formatDate(profileData.profession_card.created_at)}</span></li>
                                    )}
                                    {profileData.profession_card.updated_at && (
                                      <li><b>Updated Profession Card Date: </b> <span> {formatDate(profileData.profession_card.updated_at)}</span></li>
                                    )}
                                    {profileData.profession_card.profession_id && (
                                      <li><b>DoDigitals Profession Id : </b> <span>{profileData.profession_card.profession_id}</span></li>
                                    )}
                                    <li className="pro">
                                      <input
                                        type="text"
                                        value={`https://dodigitals.in/Verifications/${encodedId}`}
                                        readOnly
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            {/* card start */}
                            <div className="ud-cen-s3 ud-cen-s4">

                              <div className="user-admin-page">
                              <div className='profile-card-download'>
  <h2>Card Download</h2>
  <select onChange={onExtensionChange} value={fileExt} style={styles_select_input}>
    <option value="png">PNG</option>
    <option value="jpeg">JPEG</option>
    <option value="webp">WEBP</option>
    <option value="pdf">PDF</option>
  </select>
  <button onClick={() => onDownloadClick(divRef2)} style={boxstyle2}>Download</button>
</div>
                                <div ref={divRef2} className='user-card-style-in-admin-page'>
                                  <div className="ud-cen-s3 ud-cen-s4">
                                    <div className="ud-payment ud-pro-link card-dassebord-user">
                                      <div className="popup-content-front">
                                        <div className="do-digital-back-img">
                                          <img src={colorScheme === 'light' ? whitedesktopimg1URL : darkdesktopimg1URL} alt="sharp" />
                                        </div>
                                        <Row className="do-digital-card-data">
                                          <Col sm={5}>
                                            <div className="do-digital-card-data-user-profile">
                                              <Userprofileimage text={profileData.user_profile} />
                                            </div>
                                          </Col>
                                          <Col sm={7}>
                                            <div className="do-digital-card-data-user-name">
                                              <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                                <span className='user-name-in-profile'>{profileData.fname} {profileData.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                                              </p>
                                            </div>
                                            <div className="do-digital-card-data-user-id-number">
                                              <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                                <strong>ID NO.: {profileData.profession_card.profession_id}</strong>
                                              </p>
                                            </div>
                                            <div className="do-digital-card-data-site">
                                              <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                                https://dodigitals.in
                                              </p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ud-cen-s3 ud-cen-s4">
                                    <div className="ud-payment ud-pro-link card-dassebord-user">
                                      <div className="popup-content-horizontal-back">
                                        <div className="do-digital-back-img">
                                          <img src={colorScheme === 'light' ? whitedesktopimg2URL : darkdesktopimg2URL} alt="sharp" />
                                        </div>
                                        <div className="do-digital-back-side-qr">
                                          <div id="qrContainer3" style={boxstyleqrcode} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ud-cen-s3 ud-cen-s4">
                                    <div className="ud-payment ud-pro-link card-dassebord-user">
                                      <div className="do-digital-frient" id="popup-content-vertical-front">
                                        <div className="do-digital-back-img">
                                          <img src={colorScheme === 'light' ? whitemobileimg1URL : darkmobileimg1URL} alt="sharp" />
                                        </div>
                                        <div className="do-digital-card-data">
                                          <div className="do-digital-card-data-user-profile">
                                            <Userprofileimage text={profileData.user_profile} />
                                          </div>
                                          <div className="do-digital-card-data-user-name">
                                            <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                              <span className='user-name-in-profile'>{profileData.fname} {profileData.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                                            </p>
                                          </div>
                                          <div className="do-digital-card-data-user-id-number">
                                            <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                              <strong>ID NO.: {profileData.profession_card.profession_id}</strong>
                                            </p>
                                          </div>
                                          <div className="do-digital-card-data-site">
                                            <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                                              https://dodigitals.in
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ud-cen-s3 ud-cen-s4">
                                    <div className="ud-payment ud-pro-link card-dassebord-user">
                                      <div className="do-digital-frient" id="popup-content-vertical-back">
                                        <div className="do-digital-back-img">
                                          <img src={colorScheme === 'light' ? whitemobileimg2URL : darkmobileimg2URL} alt="sharp" />
                                          <div className="do-digital-back-side-qr">
                                            <div id="qrContainer4" style={boxstyleqrcode} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* card end */}

                            {profileData.profession_card.role_id === 2 && (
                              <div className="role_2">
                                {profileData.profession_card.training_certificate && (
                                  <div className="ud-cen-s3 ud-cen-s4">
                                  <h2>Training Certificate</h2>
                                  <div className="ud-payment ud-pro-link">
                                    <Serverimage text={profileData.profession_card.training_certificate} />
                                  </div>
                                </div>
                                )}
                                {profileData.profession_card.degree_certificate && (
                                    <div className="ud-cen-s3 ud-cen-s4">
                                    <h2>Degree Certificate:</h2>
                                    <div className="ud-payment ud-pro-link">
                                      <Serverimage text={profileData.profession_card.degree_certificate} />
                                    </div>
                                  </div>
                                )}
                                {profileData.profession_card.experience_letter_or_concent_form && (
                                     <div className="ud-cen-s3 ud-cen-s4">
                                     <h2>Experience Letter:</h2>
                                     <div className="ud-payment ud-pro-link">
                                       <Serverimage text={profileData.profession_card.experience_letter_or_concent_form} />
                                     </div>
                                   </div>
                                )}
                                {profileData.profession_card.salary_slip && (
                                  <div className="ud-cen-s3 ud-cen-s4">
                                  <h2>Salary Slip:</h2>
                                  <div className="ud-payment ud-pro-link">
                                    <Serverimage text={profileData.profession_card.salary_slip} />
                                  </div>
                                </div>
                                )}
                                {profileData.profession_card.reference_letter && (
                                    <div className="ud-cen-s3 ud-cen-s4">
                                    <h2>Reference Letter: </h2>
                                    <div className="ud-payment ud-pro-link">
                                      <Serverimage text={profileData.profession_card.reference_letter} />
                                    </div>
                                  </div>
                                )}
                                {profileData.profession_card.internship_certificate && (
                                      <div className="ud-cen-s3 ud-cen-s4">
                                      <h2>Internship Certificate: </h2>
                                      <div className="ud-payment ud-pro-link">
                                        <Serverimage text={profileData.profession_card.internship_certificate} />
                                      </div>
                                    </div>
                                )}
                              </div>
                            )}

                          </Tab.Pane>
                        )}
                        <Tab.Pane eventKey="old_data">

                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>


                  </div>
                  <div className="ud-rhs">

                    <div className="ud-rhs-pay">
                      <div className="ud-rhs-pay-inn">
                        <h3 style={boxstyle2}>Information</h3>
                        <ul>
                          <li><b>UserName : </b> {profileData.username}</li>
                          <li><b>Join date : </b> {formatDate(profileData.created_at)}</li>


                          <li><b>Update date : </b> {formatDate(profileData.updated_at)}</li>
                          <li><b>Pincode : </b> {profileData.zip}</li>


                        </ul>

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <p>Loading profile data...</p>
        )}
        {error && <p>{error}</p>}
      </div>
    </>
  );
};

export default Dashboard;
