import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { fetchUserDetailsById } from '../components/Api';
import '../App.css';
import * as colors from "../assets/css/Colors";
import whitedesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Desktop-1.webp';
import darkdesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Desktop-1.webp';
import Userprofileimage from '../components/Userprofileimage';
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import whitemobileimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-1.webp';
import whitemobileimg2 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-2.webp';
import darkmobileimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Mobile-1.webp';

const DecodeCard = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const secretKey = CryptoJS.enc.Utf8.parse('1234567890123456');

  const whitedesktopimg1URL = whitedesktopimg1;
  const darkdesktopimg1URL = darkdesktopimg1;
  const whitemobileimg1URL = whitemobileimg1;
  const whitemobileimg2URL = whitemobileimg2;
  const darkmobileimg1URL = darkmobileimg1;
  // Start color change auto function
  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);
  // End color change auto function



  useEffect(() => {
    const fetchQRCodeData = async () => {
      try {
        // Extract QR code URL from browser location
        const qrCodeUrl = window.location.href;

        // Extract encrypted ID and IV from URL
        const url = new URL(qrCodeUrl);
        const encryptedId = decodeURIComponent(url.pathname.split('/').pop());
        const iv = CryptoJS.enc.Base64.parse(decodeURIComponent(url.searchParams.get('iv')));

        // Decrypt the ID
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedId, secretKey, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
        const decryptedId = decryptedBytes.toString(CryptoJS.enc.Utf8);
        console.log('Decrypted ID:', decryptedId);

        // Fetch user details ONLY if decryption was successful
        if (decryptedId) {
          try {
            const response = await fetchUserDetailsById(decryptedId);
            setUserDetails(response.data); // Assuming your API returns data directly
          } catch (apiError) {
            // Handle specific API errors for a better user experience
            if (apiError.response && apiError.response.status === 404) {
              setError('User data not found for this ID.');
            } else {
              console.error('API Error:', apiError);
              setError('Failed to fetch user details. Please try again later.');
            }
          }
        } else {
          setError('Invalid QR code. Please scan a valid code.');
        }
      } catch (decryptionError) {
        console.error('Decryption Error:', decryptionError);
        setError('Invalid QR code or decryption failed. Please try again.');
      }
    };

    fetchQRCodeData();
  }, []);

  return (
    <div>
      {userDetails ? (
        <div className='user-card-style-in-admin-page Verifications-user-profile-data'>
          <div className="ud-cen-s3 ud-cen-s4 desktop-view">
            <div className="ud-payment ud-pro-link card-dassebord-user">
              <div className="popup-content-front">
                <div className="do-digital-back-img">
                  <img src={colorScheme === 'light' ? whitedesktopimg1URL : darkdesktopimg1URL} alt="sharp" />
                </div>
                <Row className="do-digital-card-data">
                  <Col sm={5}>
                    <div className="do-digital-card-data-user-profile">
                      {userDetails.data?.user_profile && (
                        <Userprofileimage text={userDetails.data.user_profile} />
                      )}
                    </div>
                  </Col>
                  <Col sm={7}>
                    <div className="do-digital-card-data-user-name">
                      <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                        <span className='user-name-in-profile'>{userDetails.data.fname} {userDetails.data.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                      </p>
                    </div>
                    <div className="do-digital-card-data-user-id-number">
                      <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                        <strong>ID NO.: {userDetails.data.dodigi_id}</strong>
                      </p>
                    </div>
                    <div className="do-digital-card-data-site">
                      <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                        https://dodigitals.in
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="Verifications-user-profile-data-card-style-mobile-view mobile-view">
            <div className="do-digital-back-img-mobile-view">
              <img src={colorScheme === 'light' ? whitemobileimg1URL : darkmobileimg1URL} alt="sharp" />
            </div>
            <div className="do-digital-card-data-mobile-view">
              <div className="do-digital-card-data-user-profile-mobile-view">
                <Userprofileimage text={userDetails.data.user_profile} />
              </div>
              <div className="do-digital-card-data-user-name-mobile-view">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  <span className='user-name-in-profile-mobile-view'>{userDetails.data.fname} {userDetails.data.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                </p>
              </div>
              <div className="do-digital-card-data-user-id-number-mobile-view">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  <strong>ID NO.: {userDetails.data.dodigi_id}</strong>
                </p>
              </div>
              <div className="do-digital-card-data-site-mobile-view">
              <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                     https://dodigitals.in
                   </p>
              </div>
            </div>
          </div>

          {userDetails.data.profession_card && (
            <div className="ud-cen-s3 ud-cen-s4">
              <div className="ud-payment ud-pro-link card-dassebord-user desktop-view">
                <div className="popup-content-front">
                  <div className="do-digital-back-img">
                    <img src={colorScheme === 'light' ? whitedesktopimg1URL : darkdesktopimg1URL} alt="sharp" />
                  </div>
                  <Row className="do-digital-card-data">
                    <Col sm={5}>
                      <div className="do-digital-card-data-user-profile">
                        {userDetails.data?.user_profile && (
                          <Userprofileimage text={userDetails.data.user_profile} />
                        )}
                      </div>
                    </Col>
                    <Col sm={7}>
                      <div className="do-digital-card-data-user-name">
                        <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                          <span className='user-name-in-profile'>{userDetails.data.fname} {userDetails.data.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                        </p>
                      </div>
                      <div className="do-digital-card-data-user-id-number">
                        <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                          <strong>ID NO.: {userDetails.data.profession_card.profession_id}</strong>
                        </p>
                      </div>
                      <div className="do-digital-card-data-site">
                        <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                          https://dodigitals.in
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="Verifications-user-profile-data-card-style-mobile-view mobile-view">
            <div className="do-digital-back-img-mobile-view">
              <img src={colorScheme === 'light' ? whitemobileimg1URL : darkmobileimg1URL} alt="sharp" />
            </div>
            <div className="do-digital-card-data-mobile-view">
              <div className="do-digital-card-data-user-profile-mobile-view">
                <Userprofileimage text={userDetails.data.user_profile} />
              </div>
              <div className="do-digital-card-data-user-name-mobile-view">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  <span className='user-name-in-profile-mobile-view'>{userDetails.data.fname} {userDetails.data.lname} <span className='ander-line-user-name' style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}></span></span>
                </p>
              </div>
              <div className="do-digital-card-data-user-id-number-mobile-view">
              <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                          <strong>ID NO.: {userDetails.data.profession_card.profession_id}</strong>
                        </p>
              </div>
              <div className="do-digital-card-data-site-mobile-view">
              <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                     https://dodigitals.in
                   </p>
              </div>
            </div>
          </div>
            </div>
          )}




        </div>

      ) : error ? (
        <p style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}>{error}</p>
      ) : (
        <p style={{ textAlign: 'center', marginTop: '20px' }}>Loading...</p>
      )}
    </div>
  );
};

export default DecodeCard;