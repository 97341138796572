import { useEffect } from 'react';

const AutoClearLocalStorage = () => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
          localStorage.removeItem('token'); 
          localStorage.removeItem('user');
        }, 30 * 60 * 1000); 
      
        return () => clearTimeout(timeoutId);
      }, []);

  return null; // This component doesn't render anything
};

export default AutoClearLocalStorage;