import React, { createContext, useState, useEffect } from 'react';
import detectBrowserLanguage from '../detectlanguage/detectBrowserLanguage';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        const detectedLanguage = detectBrowserLanguage();
        setLanguage(detectedLanguage);
    }, []);

    const toggleLanguage = () => {
        setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'hi' : 'en'));
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export { LanguageContext, LanguageProvider };
