import React, { useEffect, useState, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container, Row, Col } from 'react-bootstrap';
import Imagewebp from '../components/Image_webp';

import Darkbg from '../assets/img/indian_citizen_card.webp';
import whitebg from '../assets/img/profession-ads-dark.webp';

import Darkcardmobilebg from '../assets/img/do-digital-card/Orange-Card-1.webp';
import whitecardmobilebg from '../assets/img/do-digital-card/Green-Card-1.webp';

import Darkrightbg from '../assets/img/profession-ads.webp';
import whiterightbg from '../assets/img/indian_citizen_card-light.webp';

import Darkmobilebg from '../assets/img/do_ads1.webp';
import whitemobilebg from '../assets/img/do_ads.webp';

import Darkcardbg from '../assets/img/do-digital-card/Orange-Card-Horizontal-1.webp';
import whitecardbg from '../assets/img/do-digital-card/do-digital-card-1.webp';

// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

const Homeadsslider = () => {
  var ads = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };
  // Define image URLs and color dark mode and light mode change image start funtion

  const lightBgURL = whitebg;
  const darkBgURL = Darkbg;

  const lightrightBgURL = Darkrightbg;
  const darkrightBgURL = whiterightbg;

  const lightmobileBgURL = Darkmobilebg;
  const darkmobileBgURL = whitemobilebg;

  const lightcardBgURL = whitecardbg;
  const darkcardBgURL = Darkcardbg;

  const lightcardmobileBgURL = whitecardmobilebg;
  const darkcardmobileBgURL = Darkcardmobilebg;

  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".ban-short-links");
    if (element) {
      element.classList.add("ani");
    }
  }, []);
  // Define image URLs and color dark mode and light mode change image end funtion
// language Translate function start
const { language } = useContext(LanguageContext);

// language Translate function end

  return (
    <section>
      <div id="demo" className="carousel slide cate-sli caro-home" data-ride="carousel">
        <Container>
          <Row>
            <div className="inn">
              <div className="home-banner-slider desktop-view">
                <Slider {...ads}>
                  <div className='ad-slider-desktop'>
                    <div className="ad-slider-desktop-image">
                      <img
                        src={colorScheme === 'light' ? lightBgURL : darkBgURL}
                      />
                    </div>
                    <Row className='ad-slider-row-box'>
                      <Col sm={6} className='ad-slider-desktop-content'>
                      <img src={colorScheme === 'light' ? lightcardBgURL : darkcardBgURL} alt="sharp" />
                      </Col>
                      <Col sm={6} className='ad-slider-desktop-content-box'>
                        <h4>{translate('indiancitizencard', language)}</h4>
                        <p>{translate('ad_box_p1', language)}</p>
                      </Col>
                    </Row>
                  </div>
                  <div className='ad-slider-desktop'>
                    <div className="ad-slider-desktop-image">
                      <img
                        src={colorScheme === 'light' ? lightrightBgURL : darkrightBgURL}
                        alt="Background"
                        style={{ width: '100%', height: 'auto' }} // Adjust the styles as needed
                      />
                    </div>
                    <Row className='ad-slider-row-box'>
                      <Col sm={6} className='ad-slider-desktop-content-box'>
                        <h4>{translate('indiancitizenprofessioncard', language)}</h4>
                        <p>{translate('ad_box_p2', language)}</p>
                      </Col>
                      <Col sm={6} className='ad-slider-desktop-content'>
                      <img src={colorScheme === 'light' ? lightcardBgURL : darkcardBgURL} alt="sharp" />
                      </Col>
                    </Row>
                  </div>
                </Slider>
              </div>
              <div className="home-banner-slider mobile-view">
                <Slider {...ads} arrows={false}>
                  <div className='ad-slider-mobile'>
                  <div className="ad-slider-mobile-image">
                  <img
                        src={colorScheme === 'light' ? darkmobileBgURL : lightmobileBgURL}
                      />
                      </div>
                    <div className='ad-slider-mobile-content'>
                    <Row className='ad-slider-row-box'>
                      <Col sm={7} className='ad-slider-mobile-content'>
                      <img src={colorScheme === 'light' ? lightcardmobileBgURL : darkcardmobileBgURL} alt="sharp" />
                      </Col>
                      <Col sm={5} className='ad-slider-mobile-content-box'>
                        <h4>Indian Citizen Card</h4>
                        <p>This card allows anyone to quickly identify the cardholder's state, district, and specific area of residence</p>
                      </Col>
                    </Row>
                    </div>
                  </div>
                  <div className='ad-slider-mobile'>
                  <div className="ad-slider-mobile-image">
                  <img
                        src={colorScheme === 'light' ? darkmobileBgURL : lightmobileBgURL}
                      />
                      </div>
                    <div className='ad-slider-mobile-content'>
                    <Row className='ad-slider-row-box'>
                      <Col sm={7} className='ad-slider-mobile-content'>
                      <img src={colorScheme === 'light' ? lightcardmobileBgURL : darkcardmobileBgURL} alt="sharp" />
                      </Col>
                      <Col sm={5} className='ad-slider-mobile-content-box'>
                        <h4>Indian Citizen Card</h4>
                        <p>This card allows anyone to quickly identify the cardholder's state, district, and specific area of residence</p>
                      </Col>
                    </Row>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Homeadsslider