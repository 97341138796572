import React, { useContext } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Imagepng from '../components/Image_png';
// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

const Cardsectionabout = () => {
    // language Translate function start
const { language } = useContext(LanguageContext);

// language Translate function end
  return (
    <div className="ban-ql">
        <Container>
            <Row>
                <Col sm={3} className="mb-3">
                <div className='card-box-home'>
                            <Imagepng text={'customer'} />
                            <h4>{translate('homepoint1', language)}</h4>
                            <p>{translate('homepoint1_1', language)}</p>
                            <Link to="/Basic-Information">{translate('explorenow', language)}</Link>
                        </div>
                </Col>
                <Col sm={3} className="mb-3">
                <div className='card-box-home'>
                        <Imagepng text={'transparency'} />
                        <h4>{translate('homepoint2', language)}</h4>
                        <p>{translate('homepoint2_2', language)}</p>
                            <Link to="/Your-Profession">{translate('explorenow', language)}</Link>
                        </div>
                </Col>
                <Col sm={3} className="mb-3">
                <div className='card-box-home'>
                        <Imagepng text={'ethnic'} />
                        <h4>{translate('homepoint3', language)}</h4>
                        <p>{translate('homepoint3_3', language)}</p>
                            <Link to="/Location-Wise-Population">{translate('explorenow', language)}</Link>
                        </div>
                </Col>
                <Col sm={3} className="mb-3">
                <div className='card-box-home'>
                        <Imagepng text={'united'} />
                        <h4>{translate('homepoint4', language)}</h4>
                        <p>{translate('homepoint4_4', language)}</p>
                            <Link to="/Enhance-Transparency">{translate('explorenow', language)}</Link>
                        </div>
                </Col>
            </Row>
        </Container>
        </div>
  )
}

export default Cardsectionabout