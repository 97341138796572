import React, { useEffect, useState, useContext } from "react";
import { loginUser, switchUserLogin } from '../components/Api';
import { useNavigate } from 'react-router-dom';
import Darkimg from '../assets/img/curve2.png';
import whiteimg from '../assets/img/curve1.png';
import Darkbg from '../assets/img/user-login-dark.webp';
import whitebg from '../assets/img/user-login-light.webp';
import Darkloginimg from '../assets/img/login/login-orange.gif';
import whiteloginimg from '../assets/img/login/login-green.gif';
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as colors from "../assets/css/Colors";
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Loader from '../components/Loader';
import Imagegif from "../components/Image_gif";


const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ type: '', message: [] });
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState(null); // Define userId state
  const [ipAddress, setIpAddress] = useState(null); // Define ipAddress state

  const handleCloseModal = () => setShowModal(false);

  const [showNewPopup, setShowNewPopup] = useState(false);
  const [newPopupInfo, setNewPopupInfo] = useState({ type: '', message: [] });

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Hide all popups initially
    setShowModal(false);
    setShowNewPopup(false);

    try {
      const loginData = { id, password };
      const response = await loginUser(loginData);
      const { status } = response;
      setUserId(response.user_id); 

      // Store in localStorage 
      localStorage.setItem('userId', response.user_id);
      if (status === 200) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify({
          id: response.user_id,
          username: response.username,
          dodigital_id: response.dodigital_id,
          first_name: response.first_name,
          last_name: response.last_name,
          user_profile: response.user_profile,
          updated_at: response.updated_at,
        }));

        setUserId(response.user_id); // Store the userId
        setIpAddress(response.ip_address); // Store the ipAddress

        setMessageInfo({ type: 'success', message: ['Login successful!'] });
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate(`/${response.username}`);
        }, 5000);
      } else if (status === 500) {
        setMessageInfo({ type: 'error', message: ['Server error. Please try again later.'] });
        setShowModal(true);
      } else if (status === 405) {
        const errorMessage = response?.data?.message || 'Method not allowed.';
        setNewPopupInfo({ type: 'info', message: [errorMessage] });
        setShowNewPopup(true);
        // Store userId from the response
        setUserId(response.user_id); 

        // Store in localStorage 
        localStorage.setItem('userId', response.user_id);
      } else {
        setMessageInfo({ type: 'error', message: ['Unexpected response status.'] });
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error logging in:', error);

      const errorMessages = [];

      if (error.response) {
        const { data } = error.response;
        setUserId(data?.user_id); 
        localStorage.setItem('userId', data?.user_id);

        if (data?.error?.id) {
          errorMessages.push('The ID provided is incorrect.');
        }
        if (data?.error?.password) {
          errorMessages.push('The password provided is incorrect.');
        }
        if (data?.error?.account) {
          errorMessages.push('The account is inactive or blocked.');
        }
      }

      if (error.response?.status === 405) {
        const errorMessage = error.response?.data?.message || 'Method not allowed.';
        setNewPopupInfo({ type: 'info', message: [errorMessage] });
        setShowNewPopup(true);
        setShowModal(false);
      } else {
        setMessageInfo({
          type: 'error',
          message: errorMessages.length > 0 ? errorMessages : ['Form Error, please check.']
        });
        setShowModal(true);
        setTimeout(() => setShowModal(false), 5000);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchUserLogin = async () => {
    setLoading(true);
    try {
      // Retrieve userId from localStorage
      const storedUserId = localStorage.getItem('userId');

      // If userId is not found in localStorage, use the one from state
      const userIdToSend = storedUserId || userId;

      await switchUserLogin(userIdToSend, ipAddress);
      window.location.reload(); 
    } catch (error) {
      console.error("Error switching user login:", error);
    } finally {
      setLoading(false);
      setShowNewPopup(false);
    }
  };



  // end

  const lightImageURL = whiteimg;
  const darkImageURL = Darkimg;
  const lightBgURL = whitebg;
  const darkBgURL = Darkbg;
  const lightloginURL = whiteloginimg;
  const darkloginURL = Darkloginimg;

  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".ban-short-links");
    if (element) {
      element.classList.add("ani");
    }
  }, []);

  const { language } = useContext(LanguageContext);

  const styles = {
    background: colorScheme === 'dark'
      ? `linear-gradient(90deg, ${colors.theme_black_color}, ${colors.theme_white_color} 95%)`
      : `linear-gradient(90deg, ${colors.theme_white_color}, ${colors.theme_black_color} 95%)`,
  };

  const [focusedInput, setFocusedInput] = useState(null);

  const inputStyles = (isFocused) => ({
    borderColor: isFocused
      ? `${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`
      : '#000',
    boxShadow: isFocused
      ? 'none'
      : 'none',
    transition: 'border-color 0.3s',
  });

  const [activeLog, setActiveLog] = useState('log-1');

  const handleButtonClick = (logClass) => {
    setActiveLog(logClass);
  };

  const MessageModal = ({ show, handleClose, messageInfo }) => (
    <Modal
      show={show}
      onClick={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className={`error_massage_header ${messageInfo.type}`}>
          {messageInfo.type === 'error' ? (
            <Imagegif text={'error'} />
          ) : (
            <Imagegif text={'successfully'} />
          )}
          {show && (
            <ul className={`error_massage_contet ${messageInfo.type}`}>
              {messageInfo.message.map((msg, index) => (
                <li key={index} className={`${messageInfo.type}_message`}>
                  {messageInfo.type === 'error' ? (
                    <span className="error_icon"></span>
                  ) : (
                    <div className="success_icon">
                      <h1>Thank You!</h1>
                    </div>
                  )}
                  {msg}
                </li>
              ))}
            </ul>
          )}
        </Modal.Title>
      </Modal.Header>
    </Modal>
  );

  const NewPopupModal = ({ show, handleClose, popupInfo }) => (
    <Modal
      show={show}
      onClick={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="already-logged-user-popup">
      <Modal.Title className={`error_massage_header ${messageInfo.type}`}>
          {messageInfo.type === 'error' ? (
            <Imagegif text={'error'} />
          ) : (
            <Imagegif text={'error'} />
          )}
          {show && (
            <div className={`error_massage_contet ${messageInfo.type}`}>
          {popupInfo.type === 'info' && (
            <ul className="error_message_content">
              {popupInfo.message.map((msg, index) => (
                <>
                <li key={index} className="error_message">
                  <span className="error_icon"></span>
                  {translate('already_logged_user_popup_error1', language)}
                </li>
                <li key={index} className="error_message">
                  <span className="error_icon"></span>
                  {translate('already_logged_user_popup_error2', language)}
                </li>
                <li key={index} className="error_message">
                  <Button variant="primary" onClick={handleSwitchUserLogin} disabled={loading}>
          {loading ? 'Switching...' : 'Logout'}
        </Button>
                </li>
                </>
              ))}
            </ul>
          )}
            </div>
          )}
        </Modal.Title>
        {/* <Modal.Title className="error_message_header">
          {popupInfo.type === 'error' && <Imagegif text={'error'} />}
          {popupInfo.type === 'info' && (
            <ul className="error_message_content">
              {popupInfo.message.map((msg, index) => (
                <li key={index} className="error_message">
                  <span className="error_icon"></span>
                  {msg}
                  Do you want to switch login to this account?
                  <Button variant="primary" onClick={handleSwitchUserLogin} disabled={loading}>
          {loading ? 'Switching...' : 'Switch User'}
        </Button>
                </li>
              ))}
            </ul>
          )}
        </Modal.Title> */}
      </Modal.Header>
    </Modal>
  );



  return (
    <>
      {loading && <Loader />}
      <Header />
      <div className="str ind2-home login-page">
        <div className="hom-head" style={{ backgroundImage: `url(${colorScheme === 'light' ? lightBgURL : darkBgURL})` }}>
          <Container>
            <Row>
              <div className="ban-tit">
                <h1>
                  <b>{translate('login_heading', language)} <span style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>{translate('login_heading_span', language)}
                    <img src={colorScheme === 'light' ? lightImageURL : darkImageURL} alt="sharp" />
                  </span>
                  </b>
                  <br />
                  <span style={{ lineHeight: '32px' }}>
                    {translate('herosectioncontent', language)}
                  </span>
                </h1>
              </div>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <Row className="center-login-form">
          <Col sm={7}>
            <img src={colorScheme === 'light' ? lightloginURL : darkloginURL} alt="Do-Digital" />
          </Col>
          <Col sm={5}>
            <div className={`log log-1 ${activeLog === 'log-1' ? 'block' : 'none'}`}>
              <div className="login-main">
                <div style={styles} className="log-bor">&nbsp;</div>
                <span style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }} className="steps">{translate('dodigitals_login', language)}</span>
                <div className="log log-1">
                  <div className="login login-new">
                    <p>{translate('dodigitals_login1', language)}</p>
                    <Form onSubmit={handleLogin}>
                      <Row className="mb-3">
                        <Form.Group controlId="formGridUserId">
                          <Form.Label>{translate('username', language)} / {translate('dodigitalcardnumber', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('username', language)} / ${translate('dodigitalcardnumber', language)} ${translate('enter_your1', language)}`}
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            style={inputStyles(focusedInput === 'email')}
                            onFocus={() => setFocusedInput('email')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group controlId="formGridPassword">
                          <Form.Label>{translate('password', language)}</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder={`${translate('enter_your', language)} ${translate('password', language)} ${translate('enter_your1', language)}`}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={inputStyles(focusedInput === 'password')}
                            onFocus={() => setFocusedInput('password')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>
                      <Button variant="primary" type="submit" style={{ background: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                        {translate('login_user', language)}
                      </Button>
                    </Form>
                  </div>
                </div>
                <div className="log-bot">
                  <ul>
                    <li>
                      <span className="ll-1" onClick={() => handleButtonClick('log-1')}>Login?</span>
                    </li>
                    <li>
                      <span className="ll-3" onClick={() => handleButtonClick('log-3')}>Forgot password?</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />

      <MessageModal
        show={showModal}
        handleClose={handleCloseModal}
        messageInfo={messageInfo}
      />
      <NewPopupModal
        show={showNewPopup}
        handleClose={() => setShowNewPopup(false)}
        popupInfo={newPopupInfo}
      />
    </>
  );
};

export default Login;
