import React, { useEffect, useState, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaChevronRight } from "react-icons/fa";
import Imagegif from '../components/Image_gif';
// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

// animtion dark and light
import Darkimg from '../assets/img/server/do-gif-orange.gif';
import whiteimg from '../assets/img/server/do-gif-green.gif';

import { FaHandPointRight } from "react-icons/fa";

const Aboutsectionhomepage = () => {

    const lightImageURL = whiteimg;
    const darkImageURL = Darkimg;


        // language Translate function start
const { language } = useContext(LanguageContext);

 // Start color change auto function
 const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);
// End color change auto function

// language Translate function end
  return (
    <section>
    <div className="str">
       <Container>
            <Row>

                <div className="mob-app">
                    <div className="lhs">
                    <img src={colorScheme === 'light' ? lightImageURL : darkImageURL} alt="Do-Digital" />
                    </div>
                    <div className="rhs">
                        <h2>{translate('do_digitals', language)}</h2>
                        <span>{translate('about_section_1', language)}</span>
                        <ul>
                            <li><FaHandPointRight /> <strong>{translate('indiancitizencard', language)}</strong><br></br>
                            <span>{translate('about_section_2', language)}</span>
                            </li>
                            <li><FaHandPointRight /> <strong>{translate('indiancitizenprofessioncard', language)}</strong><br></br>
                            <span>{translate('about_section_3', language)}</span></li>
                            <span>{translate('about_section_4', language)}</span>
                        </ul>
                    </div>
                </div>
                </Row>
            </Container>
    </div>
</section>
  )
}

export default Aboutsectionhomepage