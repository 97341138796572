import React, { useEffect, useState, useContext } from "react";
import Darkimg from '../assets/img/curve2.png';
import whiteimg from '../assets/img/curve1.png';

import Darkgif from '../assets/img/sara-dark-main.gif';
import whitegif from '../assets/img/sara-light-main.gif';

import Darkbg from '../assets/img/india.webp';
import whitebg from '../assets/img/india-light.webp';

import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import * as colors from "../assets/css/Colors";

// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

const Hero = () => {
  // Define image URLs and color dark mode and light mode change image start funtion
  const lightImageURL = whiteimg;
  const darkImageURL = Darkimg;

  const lightGifURL = whitegif;
  const darkGifURL = Darkgif;

  const lightBgURL = whitebg;
  const darkBgURL = Darkbg;

  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".ban-short-links");
    if (element) {
      element.classList.add("ani");
    }
  }, []);
  // Define image URLs and color dark mode and light mode change image end funtion

  // hover to change background color border and box shadow function start
  const [hovered, setHovered] = useState({});

  const handleMouseEnter = (id) => {
    setHovered(prevState => ({ ...prevState, [id]: true }));
  };

  const handleMouseLeave = (id) => {
    setHovered(prevState => ({ ...prevState, [id]: false }));
  };
  const divStyles = (isHovered) => ({
    backgroundColor: isHovered
      ? colorScheme === 'dark'
        ? colors.theme_black_color
        : colors.theme_white_color
      : '#181818c7',
    border: isHovered
      ? colorScheme === 'dark'
        ? `1px solid ${colors.theme_black_color}`
        : `1px solid ${colors.theme_white_color}`
      : '1px solid #626262',
    boxShadow: isHovered ? '0 6px 13px 6px #00000042' : 'none',
    transition: 'background-color 0.3s ease',
  });
  // hover to change background color border and box shadow function start

  // language Translate function start
  const { language } = useContext(LanguageContext);

  // language Translate function end

  return (
    <div className="str ind2-home">
      <div className="hom-head" style={{ backgroundImage: `url(${colorScheme === 'light' ? lightBgURL : darkBgURL})` }}>
        <Container>
          <Row>
            <div className="ban-tit">
              <h1>
                <b>{translate('createcard', language)} <span style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}> {translate('citizencard', language)}
                  <img
                    src={colorScheme === 'light' ? lightImageURL : darkImageURL} alt="sharp"
                  />
                </span>
                </b>
                <br></br>
                <span style={{ lineHeight: '32px' }}>
                  {translate('herosectioncontent', language)}
                </span>
              </h1>
            </div>
            <div className="ban-short-links">
              <ul className="category-hero-section">
                <li>
                  <div className="hero-section-box"
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={() => handleMouseLeave(1)}
                    style={divStyles(hovered[1])}
                  >
                    <img
                      src={colorScheme === 'light' ? lightGifURL : darkGifURL} alt="sharp"
                    />
                    <h4>{translate('indiancitizencard', language)}</h4>
                    <Link to="/India-Citizen-Card" className="fclick"></Link>
                  </div>
                </li>
                <li>
                  <div className="hero-section-box"
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={() => handleMouseLeave(2)}
                    style={divStyles(hovered[2])}
                  >
                    <img
                      src={colorScheme === 'light' ? lightGifURL : darkGifURL} alt="sharp"
                    />
                    <h4>{translate('indiancitizenprofessioncard', language)}</h4>
                    <Link to="/India-Citizen-Profession-Card" className="fclick"></Link>
                  </div>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Hero