import React from 'react';

const Image_png = ({ text }) => {
  return (
    <img
      src={`assets/img/${text}.png`}
      alt={text}
      loading="lazy"
    />
  );
};

export default Image_png;
