import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './layout/Header';
import Footer from './layout/Footer'
import * as colors from "./assets/css/Colors";
// language
import { LanguageContext } from './detectlanguage/LanguageContext';
import translate from "./detectlanguage/translate";
import Darkbg from './assets/img/bg-1.webp';
import whitebg from './assets/img/bg-1-light.webp';
import Darkimg from './assets/img/curve2.png';
import whiteimg from './assets/img/curve1.png';
import { FcHighPriority } from "react-icons/fc";

const Basicinformation = () => {

    const [hoverTab, setHoverTab] = useState(null);
    const getTabStyle = (tabKey) => {
        if (hoverTab === tabKey) {
            return {
                border: `1px dashed ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        } else {
            return {
                backgroundColor: colorScheme === 'dark' ? colors.theme_black_color_bg : colors.theme_white_color_bg,
                color: 'black',
                border: `1px dashed ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`,
            };
        }
    };



    const tabs = [
        { key: 'indiapopulation1', label1: 'basic_information1_1', label2: 'basic_information1_2' },
        { key: 'indiapopulation2', label1: 'basic_information2_1', label2: 'basic_information2_2' },
        { key: 'indiapopulation3', label1: 'basic_information3_1', label2: 'basic_information3_2' },
        { key: 'indiapopulation4', label1: 'basic_information4_1', label2: 'basic_information4_2' },
        { key: 'indiapopulation5', label1: 'basic_information5_1', label2: 'basic_information5_2' },
       
      ];

    const lightImageURL = whiteimg;
    const darkImageURL = Darkimg;
    const lightBgURL = whitebg;
    const darkBgURL = Darkbg;
    // Start color change auto function
    const [colorScheme, setColorScheme] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    );
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => {
            setColorScheme(e.matches ? 'dark' : 'light');
        };
        mediaQuery.addEventListener('change', handleChange);
        return () => {
            mediaQuery.removeEventListener('change', handleChange);
        };
    }, []);
    // End color change auto function
    // language Translate function start
    const { language } = useContext(LanguageContext);
    return (
        <>
            <section>
                <div className="str ind2-home">
                    <div className="hom-head" style={{ backgroundImage: `url(${colorScheme === 'light' ? lightBgURL : darkBgURL})` }}>
                        <Header />
                        <Container>
                            <Row>
                                <div className="ban-tit">
                                    <h1>
                                        <b style={{ textAlign: colorScheme === 'dark' ? 'center' : 'left' }}>{translate('basic_information1', language)}
                                        </b>
                                        <br></br>
                                        <span style={{ lineHeight: '32px', maxWidth: colorScheme === 'dark' ? '100%' : '600px', width: '100%', display: 'block', margin: '0 auto 0 0' }}>
                                            {translate('basic_information2', language)}
                                        </span>
                                    </h1>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
            <section className="mt-0 mb-60">
                <Container>
                    <Row className="justify-content-center">
                    {tabs.map((tab) => (
        <Col sm={6} className='mb-3 mt-3' key={tab.key}>
          <div
            className="basicinformation-box-main"
            style={getTabStyle(tab.key)}
            onMouseEnter={() => setHoverTab(tab.key)}
            onMouseLeave={() => setHoverTab(null)}
          >
            <div className="basicinformation-box-main-box-1">
              <div className="s1">
                <div className="lhs">
                  <FcHighPriority />
                </div>
                <div className="rhs">
                  <h4>{translate(tab.label1, language)}</h4>
                </div>
              </div>
              <div className="s2">
                <div className="lhs">
                  <span>{translate(tab.label2, language)}</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default Basicinformation